import React, { useMemo, useState, useRef, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { Col, Container, Input, Label, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  generateColumns,
  generatePayload,
  mapInvoiceDetails,
  initialTableRowValues,
  getPaymentTypes,
  focusLastSelectItemNumberInput,
  focusQuantityNumberInput,
  initialInvDetailsValues,
  initialPaymentDetailsValues,
} from "./helper";
import WhTableContainer from "../../Components/Common/WhTables";
import DeleteModal from "../../Components/Common/DeleteModal";
import ConfirmationModal from "../../Components/Common/Confirmation";
import CustomerAddrMsgModal from "./CustomerAddrMsgModal";
import PopUpMsgModal from "./PopUpMsgModal";
import SmartSearch from "./SmartSearch";
import RouteChangeConfirmation from "../../Components/Common/RouteChangeConfirmation";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
import InvoiceResetConfirm from "./InvoiceResetConfirm";
//Components
import SearchCustomerBox from "../../Components/SalesWH/searchcustomerbox";
import SalesActions from "../../Components/SalesWH/salesactions";
import InvoiceDetails from "../../Components/SalesWH/invoicedetails";
import InvoiceStatusInfo from "../../Components/SalesWH/invoicestatusinfo";
import InvoiceCalculationsSidebar from "../../Components/SalesWH/invoicecalculationssidebar";
//Modals
import PrintModal from "../../Components/SalesWH/modals/printmodal";
import PaymentsModal from "../../Components/SalesWH/modals/paymentsmodal";
import PaymentsModalBody from "../../Components/SalesWH/modals/paymentsmodal/paymentsmodalbody";
import PurcahseHistoryModal from "../../Components/SalesWH/modals/purchasehistorymodal";
import OpenInvoiceConfirmModal from "../../Components/SalesWH/modals/openinvoiceconfirmmodal";
import OverridePasswordModal from "../../Components/SalesWH/modals/overridepasswordmodal";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Constants
import { invoiceTotalLimit as totalLimit } from "../../constants";
//Actions
import { getAllCustomersAct, getMasterDataByCategoryAct, getCustomerAct, getCustomerInventoryDiscountsAct } from "../../store/customer/customeractions";
import {
  getInventoryListAct,
  createCustomerInventoryPriceAct,
  getInventoryItemAct,
} from "../../store/inventory/inventoryactions";
import {
  createInvoiceAct,
  updateInvoiceAct,
  deleteInvoiceAct,
  getMaxInvoiceNoAct,
  getInvoiceDetailsAct,
  changePostedStatusOfInvoiceAct,
  downloadInvoicePdfAct,
} from "../../store/sales/salesactions";
import { getDriversListAct, getSalesmanListAct } from "../../store/masterdata/masterdataactions";
import { getMasterDataForSupplierAct } from "../../store/supplier/supplieractions";
import { logoutUser } from '../../store/actions';
//Context
import { PermissionsContext } from "../../context/PermissionsContext";
//HOC
import withPermission from "../../hoc/withPermission";
import useScanDetection from "use-scan-detection";
import Draggable from "react-draggable";
import F12CloseModalListener from "../../Components/Common/F12close";
import InactiveModal from "./InactiveModal";
import FooterWH from "./FooterWH";
import { getInvPricingReportPrintAct } from "../../store/reports/reportsactions";
import {formatAmount, formatAmountSymbol} from "../../utility/commonutility"
const tableStyles = {
  borderCollapse : 'separate',
  borderSpacing : '0px',
  border:'2px solid black'
}
const tdStyle = {
  padding : '0.1rem 0.3rem',
  fontWeight:600
}
const thdStyle = {
  padding : '0.2rem 0.9rem',
  fontWeight:600,
}
const thStyle ={
  border:'5px solid black !important'
}
// const thsticky = {
//   position: 'sticky',
//   backgroundColor: '#f8f9fa',
//   top: 0,
//   zIndex:100,
//   border :'1px solid black '
// }

const boldTextCust = {
  fontWeight:600,
}

function SalesWH({ history }) {
  const { id } = useParams();
  const { permissions, handleLogout } = useContext(PermissionsContext);
  const [sortBy, setSortBy] = useState("");
  const isSortByClicked = useRef(false);

  const [isBlocked, setIsBlocked] = useState(false);
  const tableContainerRef = useRef(null);
  const [search, setSearch] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    customerNumber: "",
    customerName: "",
    companyName: "",
  });
  const [isGetSalesDisc, setIsSalesDisc] = useState(false);
  const [discount, setDiscount] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [tax, setTax] = useState("");
  const [freight, setFreight] = useState("");
  const [other, setOther] = useState("");
  const [sgst, setSgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [prevBalance, setPrevBalance] = useState(0);
  const [adjustInBalance, setAdjustInBalance] = useState(false);
  const [status, setStatus] = useState(id === "new" ? "open" : "posted");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showOpenInvoiceConfirm, setShowOpenInvoiceConfirm] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [tableData, setTableData] = useState([{ ...initialTableRowValues }]);
  const [purchaseHistoryItem, setPurchaseHistoryItem] = useState({ itemNumber: "", description: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSetPriceConfirmModal, setShowSetPriceConfirmModal] = useState(false);
  const [showPurchaseHistoryModal, setShowPurchaseHistoryModal] = useState(false);
  const [showOverridePasswordModal, setShowOverridePasswordModal] = useState(false);
  // const [showCustomerAddrMsgModal, setShowCustomerAddrMsgModal] = useState(false);
  const [showPopUpMsgModal, setShowPopUpMsgModal] = useState(false);
  const [showSmartSearchModal, setShowSmartSearchModal] = useState(false);
  const [showInvoiceResetConfirmModal, setShowInvoiceResetConfirmModal] = useState(false);
  const [overrideModaltext, setOverrideModalText] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState(() => getPaymentTypes({}));
  const [isCreditHold, setIsCreditHold] = useState(false);
  const [isBillToBill, setIsBillToBill] = useState(false);
  const [currentBalance, setCurrentBalance] = useState('')
  const [invDiscount,setInvDiscount] = useState("");
  const tableRef = useRef();

  const isDownloadBtnClicked = useRef(false);

  const isBarcodeScanDetected = useRef(false);

  const [activationModalConfig, setActivationModalConfig] = useState({
    show: false,
    itemNumber: "",
    description: "",
  });

  useScanDetection({
    onComplete: (num) => {
      if (!isNaN(Number(num))) {
        if(customerDetails?.customerNumber) {
          dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), { upcCode: num }));
          isBarcodeScanDetected.current = true;
        } else {
          toast.error("Please select customer");
        }
      }
    },
    minLength: 12,
  });
  const closeItemActivationModal = () => {
    setActivationModalConfig({
      show: false,
      itemNumber: "",
      description: "",
    });
  };
  const dispatch = useDispatch();

  //Change focus to last item number if true otherwise focus quantity of currently added item
  const isFocusLastItemNumberInput = useRef(false);
  const isfocusQuantityNumberInput = useRef(false);
  const indexOfCurrentAddedItem = useRef("");
  const isPaymentAnywhereModelOpen = useRef(false);

  const isPaymetDetailsSubmitted = useRef(false);
  const shouldDownloadPdf = useRef(false);
  const tempTableDataForOverridePassword = useRef([]);
  const isOverridePasswordEnteredDetails = useRef({
    itemSalesLimit: false,
    customerSalesLimit: false,
    overridePasswordFor: "", //customerSalesLimit or itemSalesLimit
  });
  //for storing item number temporarily when overriding password
  const tempItemNumber = useRef("");

  const customerPriceModalData = useRef({ itemNumber: "", newPrice: "" });
  const recentlyAddedItemPos = useRef("");
  const isRecentItemHasCustSpPrice = useRef(false);

  const isRouteChangeApiCalled = useRef(false);
  const isCreateInvoiceBtnClicked = useRef(false);

  const updateConfirmConfig = useRef(null);



  const currentCustomerListPage = useRef(1);
  const dropdownBtnClicked = useRef(false);
  const callbackOnCustomerListApiResp = useRef(() => {});

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const toggleSetPriceConfirmModal = () => setShowSetPriceConfirmModal((prev) => !prev);
  const togglePurchaseHistoryModal = () => setShowPurchaseHistoryModal(!showPurchaseHistoryModal);
  const toggleOverridePasswordModal = () => setShowOverridePasswordModal(!showOverridePasswordModal);
  const toggleInvoiceResetConfirmModal = () => setShowInvoiceResetConfirmModal(!showInvoiceResetConfirmModal);
  // const toggleCustomerAddrMsgModal = () => {
  //   setShowCustomerAddrMsgModal(!showCustomerAddrMsgModal);
  //   if (showCustomerAddrMsgModal) {
  //     focusLastSelectItemNumberInput();
  //   }
  // };

  const togglePopUpMsgModal = () => {
    // if (showPopUpMsgModal) toggleCustomerAddrMsgModal();
    setShowPopUpMsgModal(!showPopUpMsgModal);
  };
  const toggleSmartSearchModal = () => setShowSmartSearchModal(!showSmartSearchModal);
  function toggle() {
    setModal(!modal);
  }
  function toggleOpenInvoiceConfirm() {
    setShowOpenInvoiceConfirm(!showOpenInvoiceConfirm);
  }
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }

  function togglePaymentsModal() {
    if (
      totalAmount + Number(tax) + Number(freight) + Number(other) - discountValue + prevBalance >= 0
        ? permissions["ACCEPT_PAYMENTS"]
        : permissions["RETURN_PAYMENTS"]
    )
      setShowPaymentsModal(!showPaymentsModal);
      setShowPaymentsModal(!showPaymentsModal);
  }
  const handelOpenPaymentsModal = () => {
    if (!customerDetails.customerNumber) toast.error("Please select customer");
    else togglePaymentsModal();
  };

  useEffect(() => {
    if (status === "open" && customerDetails.customerNumber) {
      setIsBlocked(true)
    }
    else setIsBlocked(false);
  }, [status, customerDetails.customerNumber]);

  const invDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: initialInvDetailsValues,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      //
    },
  });

  const totalAmount = useMemo(() => {
    let totalAmt = 0;
    tableData.forEach((item) => {
      // let amountVal = Number(item.amount) || 0;
      // let disPerVal = Number(item.discount) || 0;
      // let disVal = (item.amount * disPerVal) / 100;
      totalAmt += item.itemNumber ? item.price * item.quantity : 0;
    });
    return totalAmt;
  }, [tableData]);

  const setSgstPercentageParent = (value) => {
    setSgst(value);
  };

  const setCgstPercentageParent = (value) => {
    setCgst(value);
  };

  const setIgstPercentageParent = (value) => {
    setIgst(value);
  };

  const sgstAmount = useMemo(() => {
    if (typeof sgst === 'number' && !isNaN(sgst)) {
      return (totalAmount * sgst) / 100;
    } else {
      return 0;
    }
  }, [totalAmount, sgst]);
  
  const cgstAmount = useMemo(() => {
    if (typeof cgst === 'number' && !isNaN(cgst)) {
      return (totalAmount * cgst) / 100;
    } else {
      return 0;
    }
  }, [totalAmount, cgst]);

  const igstAmount = useMemo(() => {
    if (typeof igst === 'number' && !isNaN(igst)) {
      return (totalAmount * igst) / 100;
    } else {
      return 0;
    }
  }, [totalAmount, igst]);
  
  const subtotal = useMemo(() => totalAmount + sgstAmount + cgstAmount + igstAmount, [
    totalAmount,
    sgstAmount,
    cgstAmount,
    igstAmount,
  ]);

  const invTotal = useMemo(
    () => subtotal + Number(tax) + Number(freight) + Number(other) - discountValue,
    [subtotal, tax, freight, discountValue, other]
  );
  const balanceDue = useMemo(() => prevBalance + invTotal, [prevBalance, invTotal]);

  useEffect(() => {
    if (!showPaymentsModal) {
      paymentDetailsValidation.resetForm();
    }
  }, [showPaymentsModal]);

  const paymentDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: initialPaymentDetailsValues,
    validationSchema:
    balanceDue >= 0 ? Yup.object({
      payments: Yup.array().of(
        Yup.object({
          amountPaid: Yup.string().required('Amount Paid is required'),
          paymentType: Yup.string().required('Payment Type is required'),
          chequeNo: Yup.string().test('required', '', function (value) {
            const parent = this.parent;
            const paymentType = parent.paymentType;
            
            if (paymentType === 'Check') {
              return value ? true : this.createError({ message: 'Cheque No is required' });
            }
            if (paymentType === 'UPI') {
              return value ? true : this.createError({ message: 'UPI No is required' });
            }
    
            return true;
          }),
        })
      ),
    }) 
    : Yup.object({}),
    onSubmit: (values) => {
      isPaymetDetailsSubmitted.current = true;
      createInvoiceHandler(true);
    },
  });

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "F3") {
        event.preventDefault();
        if (customerDetails.customerNumber) toggleSmartSearchModal();
        else toast.error("Please select customer");
      }
    },
    [showSmartSearchModal, customerDetails]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

    useEffect(() => {
    if (!activationModalConfig.show) {
      focusLastSelectItemNumberInput(indexOfCurrentAddedItem.current);
    }
  }, [activationModalConfig.show]);
  
  const {
    loginDetails,
    getAllCustomersResp,
    getCustomerResp,
    createInvoiceResp,
    updateInvoiceResp,
    deleteInvoiceResp,
    getMaxInvoiceNoResp,
    getInvoiceDetailsResp,
    getDriversListResp,
    getSalesmanListResp,
    getMasterDataForSupplierResp,
    getMasterDataByCategoryResp,
    changePostedStatusOfInvoiceResp,
    createCustomerInventoryPriceResp,
    getInventoryItemResp,
    getSalesOfGivenItemResp,
    downloadInvoicePdfResp,
    getInventoryListResp,
    getInventoryListLoading,
    getCustomerInventoryDiscountsResp,
    salesErrorResp,
    customerErrResp,
    masterDataErrorResp,
    supplierErrorResp,
    inventoryErrorResp,
    salesApiLoading,
    getInvPricingReportLoading
  } = useSelector(
    ({ Login, customer, sales, masterData, supplier,reports, inventory }) => ({
      loginDetails: Login.loginDetails,
      getAllCustomersResp: customer.getAllCustomersResp,
      getCustomerResp: customer.getCustomerResp,
      createInvoiceResp: sales.createInvoiceResp,
      updateInvoiceResp: sales.updateInvoiceResp,
      deleteInvoiceResp: sales.deleteInvoiceResp,
      getMaxInvoiceNoResp: sales.getMaxInvoiceNoResp,
      getInvoiceDetailsResp: sales.getInvoiceDetailsResp,
      getDriversListResp: masterData.getDriversListResp,
      getSalesmanListResp: masterData.getSalesmanListResp,
      getMasterDataForSupplierResp: supplier.getMasterDataForSupplierResp,
      getMasterDataByCategoryResp: customer.getMasterDataByCategoryResp,
      changePostedStatusOfInvoiceResp: sales.changePostedStatusOfInvoiceResp,
      createCustomerInventoryPriceResp: inventory.createCustomerInventoryPriceResp,
      getInventoryItemResp: inventory.getInventoryItemResp,
      getSalesOfGivenItemResp: inventory.getSalesOfGivenItemResp,
      downloadInvoicePdfResp: sales.downloadInvoicePdfResp,
      getInventoryListResp: inventory.getInventoryListResp,
      getInventoryListLoading: inventory.getInventoryListLoading,
      getCustomerInventoryDiscountsResp: customer.getCustomerInventoryDiscountsResp,
      salesErrorResp: sales.salesErrorResp,
      customerErrResp: customer.customerErrResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
      supplierErrorResp: supplier.supplierErrorResp,
      inventoryErrorResp: inventory.inventoryErrorResp,
      salesApiLoading: sales.salesApiLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
    }),
    shallowEqual
  );

  const handleLogoutUser = () => {
    dispatch(logoutUser(generateApiUrl('logout_user')));
    handleLogout();
  }

  const isInvoiceSavedAfterSessionExpired = useRef(false);

  useEffect(() => {
    if(loginDetails?.sessionExpired) {
      if(isBlocked) {
        createInvoiceHandler(false);
        isInvoiceSavedAfterSessionExpired.current = true;
      }
      else {
        handleLogoutUser();
      }
    }
  }, [loginDetails, isBlocked]);
  const {
    salesLimit: customerSalesLimit,
    creditLimit: customerCreditLimit,
    popMemo,
    isOutOfState,
  } = getCustomerResp?.data?.customer || {};
   
  const { paid, fBalance, totalPaid: totalPaidOnInvoice } = getInvoiceDetailsResp?.data?.invoice || {};
  const customerResp = useRef({
    prevGetAllCustomersResp: getAllCustomersResp,
    prevGetCustomerResp: getCustomerResp,
    prevCreateInvoiceResp: createInvoiceResp,
    prevUpdateInvoiceResp: updateInvoiceResp,
    prevDeleteInvoiceResp: deleteInvoiceResp,
    prevGetInvoiceDetailsResp: getInvoiceDetailsResp,
    prevChangePostedStatusOfInvoiceResp: changePostedStatusOfInvoiceResp,
    prevCreateCustomerInventoryPriceResp: createCustomerInventoryPriceResp,
    prevGetInventoryItemResp: getInventoryItemResp,
    prevDownloadInvoicePdfResp: downloadInvoicePdfResp,
    prevGetInventoryListResp: getInventoryListResp,
    prevGetCustomerInventoryDiscountsResp: getCustomerInventoryDiscountsResp,
    prevSalesErrorResp: salesErrorResp,
    prevCustomerErrResp: customerErrResp,
    prevMasterDataErrorResp: masterDataErrorResp,
    prevSupplierErrorResp: supplierErrorResp,
    prevInventoryErrorResp: inventoryErrorResp,
  });
  useEffect(() => {
    const {
      prevGetAllCustomersResp,
      prevGetCustomerResp,
      prevCreateInvoiceResp,
      prevUpdateInvoiceResp,
      prevDeleteInvoiceResp,
      prevGetInvoiceDetailsResp,
      prevChangePostedStatusOfInvoiceResp,
      prevCreateCustomerInventoryPriceResp,
      prevGetInventoryItemResp,
      prevDownloadInvoicePdfResp,
      prevGetInventoryListResp,
      prevGetCustomerInventoryDiscountsResp,
      prevSalesErrorResp,
      prevCustomerErrResp,
      prevMasterDataErrorResp,
      prevSupplierErrorResp,
      prevInventoryErrorResp,
    } = customerResp.current || {};
    if (getAllCustomersResp && prevGetAllCustomersResp !== getAllCustomersResp) {
      const list = [];
      (getAllCustomersResp?.data?.customers || []).forEach(({ customerNumber, firstName, lastName, companyName }) => {
        list.push({
          customerNumber,
          customerName: `${firstName || ""} ${lastName || ""}`,
          companyName,
        });
      });
      if (!dropdownBtnClicked.current) setSearchListOptions(list);
      else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getAllCustomersResp?.data?.customers?.length > 0) callbackOnCustomerListApiResp.current();
        currentCustomerListPage.current = Number(getAllCustomersResp?.data?.page) + 1;
      }
    }
    if (getCustomerResp && prevGetCustomerResp !== getCustomerResp) {
      if (!isPaymentAnywhereModelOpen.current) {
        const {
          customerNumber,
          companyName,
          firstName,
          lastName,
          currentBalance,
          shippedVia,
          getSalePrices,
          isCreditHold,
          creditCardHold,
          isBillToBill,
          isNoChecks,
          isTaxExempt,
          flatNumber,
          street,
          landmark,
          city,
          state,
          phone,
          zip,
          taxId,
          email,
          popMemo,
          popUp,
          driverName,
          paymentTerms,
          isOutOfState
        } = getCustomerResp?.data?.customer || {};
        setPaymentTypes(() => getPaymentTypes({ creditCardHold, isNoChecks }));
        setIsCreditHold(isCreditHold)
        setIsBillToBill(isBillToBill)
        setCurrentBalance(currentBalance)
        setSearch(companyName);
        setCustomerDetails((prev) => ({
          ...prev,
          isOutOfState,
          customerNumber,
          customerName: `${firstName || ""} ${lastName || ""}`,
          companyName,
          isTaxExempt,
          flatNumber,
          street,
          landmark,
          city,
          state,
          phone,
          zip,
          taxId,
          email,
        }));
        setSortBy("");
        if (id === "new") {
          if (popUp && popMemo) {
            togglePopUpMsgModal();
          } 
          // else toggleCustomerAddrMsgModal();
          setIsSalesDisc(getSalePrices);
          invDetailsValidation.setValues((prev) => ({
            ...prev,
            shippedVia: shippedVia || "",
            driverName: driverName || "",
            paymentTerms: paymentTerms || "",
          }));
        }
        setPrevBalance(currentBalance || 0);
        const items = [];
        tableData.forEach(item => {
          if(item.itemNumber) items.push(item.itemNumber);
        });
        if(items.length) dispatch(getCustomerInventoryDiscountsAct(generateApiUrl("get_customer_inventory_discounts",{customerNumber}), {items}));
      }
    }
    if (updateInvoiceResp && prevUpdateInvoiceResp !== updateInvoiceResp) {
      if(isInvoiceSavedAfterSessionExpired.current) {
        handleLogoutUser();
        isInvoiceSavedAfterSessionExpired.current = false;
      } else {
        if (!isRouteChangeApiCalled.current) {
          if (shouldDownloadPdf.current)
            dispatch(
              downloadInvoicePdfAct(
                generateApiUrl("download_invoice_pdf", { invoiceNumber: id }),
                {},
                { responseType: "blob" }
              )
            );
          toast.success("Successfully updated invoice details");
          paymentDetailsValidation.setValues(initialPaymentDetailsValues);
          dispatch(getInvoiceDetailsAct(generateApiUrl(`get_invoice_details`, { invoiceNumber: id })));
          if (showPaymentsModal){
            togglePaymentsModal();
          }
        } else isRouteChangeApiCalled.current = false;
      }
    }
    if (createInvoiceResp && prevCreateInvoiceResp !== createInvoiceResp && !showInvoiceResetConfirmModal) {
      if(isInvoiceSavedAfterSessionExpired.current) {
        handleLogoutUser();
        isInvoiceSavedAfterSessionExpired.current = false;
      }
      else {
        if (!isRouteChangeApiCalled.current) {
          isCreateInvoiceBtnClicked.current = true;
          setIsBlocked(false);
        } else isRouteChangeApiCalled.current = false;
      }
    }
    if (createInvoiceResp && prevCreateInvoiceResp !== createInvoiceResp && showInvoiceResetConfirmModal) {
      initializeInvoiceDetails();
      toggleInvoiceResetConfirmModal();
      setIsBlocked(false);
    }
    if (deleteInvoiceResp && prevDeleteInvoiceResp !== deleteInvoiceResp) {
      history.push("/warehousesales", { showDeleteInvoiceSuccess: true });
    }
    if (getInvoiceDetailsResp && prevGetInvoiceDetailsResp !== getInvoiceDetailsResp) {
      mapInvoiceDetails(
        getInvoiceDetailsResp,
        setTableData,
        setCustomerDetails,
        setStatus,
        invDetailsValidation.setValues,
        paymentDetailsValidation.setValues,
        setTax,
        setDiscount,
        setDiscountValue,
        setIsSalesDisc,
        setFreight,
        setOther,
        setSgstPercentageParent,
        setCgstPercentageParent,
        setIgstPercentageParent,
        setInvDiscount
      );
      dispatch(
        getCustomerAct(
          generateApiUrl(`get_customer`, { customerNo: getInvoiceDetailsResp?.data?.invoice?.soldToCustomer })
        )
      );
    }
    if (changePostedStatusOfInvoiceResp && prevChangePostedStatusOfInvoiceResp !== changePostedStatusOfInvoiceResp) {
      toast.success(`Successfully changed the invoice to ${status === "posted" ? "open" : "posted"} state`);
      toggleOpenInvoiceConfirm();
      dispatch(getInvoiceDetailsAct(generateApiUrl(`get_invoice_details`, { invoiceNumber: id })));
    }
    if (createCustomerInventoryPriceResp && prevCreateCustomerInventoryPriceResp !== createCustomerInventoryPriceResp) {
      toast.success(`Successfully set this price for current customer`);
      customerPriceModalData.current = { itemNumber: "", newPrice: "" };
      toggleSetPriceConfirmModal();
    }
    if (getInventoryItemResp && prevGetInventoryItemResp !== getInventoryItemResp) {
      if (getCustomerResp && !isRecentItemHasCustSpPrice.current) {
        const { code, pricingType,isOutOfState } = getCustomerResp.data.customer;
        setCustomerDetails((prevDetails) => ({
          ...prevDetails,
          isOutOfState,
        }))
        if (pricingType === "code" && code) {
          const { priceA, priceB, priceC, priceD } = getInventoryItemResp.data.inventory;
          let tempPrice = "";
          if (code === "A") tempPrice = priceA;
          else if (code === "B") tempPrice = priceB;
          else if (code === "C") tempPrice = priceC;
          else if (code === "D") tempPrice = priceD;
          if (tempPrice !== "" && tempPrice !== null) {
            setTableData((prev) => {
              const updatedTableData = [...prev],
                rowData = { ...updatedTableData[recentlyAddedItemPos.current] };
              const updatedRowData = {...rowData};
              updatedRowData.codePrice = tempPrice || rowData.price;
              if(!isGetSalesDisc || !rowData.originalDisc) {
                updatedRowData.price = tempPrice || rowData.price;
                updatedRowData.discountVal = (rowData.amount - (tempPrice || rowData.price)).toFixed(2);
                updatedRowData.discount =(((rowData.amount - (tempPrice || rowData.price)) / rowData.amount) * 100).toFixed(2);
              }
              updatedTableData.splice(recentlyAddedItemPos.current, 1, updatedRowData);
              return updatedTableData;
            });
          }
        }
      }
    }
    if (downloadInvoicePdfResp && prevDownloadInvoicePdfResp !== downloadInvoicePdfResp) {
      if (shouldDownloadPdf.current) {
      const file = new File([downloadInvoicePdfResp], "invoice.pdf", {
        type: "application/pdf",
      });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "Invoice.pdf");
      document.body.appendChild(a);
      a.click();
      a.remove();
      shouldDownloadPdf.current = false;
      if(id === "new") history.push(`/warehousesales/${getMaxInvoiceNoResp?.data?.invoiceNumber}`);
    }
    }
    if (getInventoryListResp && prevGetInventoryListResp !== getInventoryListResp) {
      if (isBarcodeScanDetected.current && Array.isArray(getInventoryListResp?.data?.inventories)) {
        isBarcodeScanDetected.current = false;
        if (getInventoryListResp.data.inventories.length === 0) toast.error("No product found for scanned barcode");
        else if (getInventoryListResp.data.inventories.length > 1)
          toast.error("More than one products found for scanned barcode");
        else {
          const {
            cost,
            description,
            itemNumber,
            retail,
            supplierItemNumber,
            weight,
            quantityInStock,
            casePack,
            salesLimit,
            discount,
            customerSpecialPrice,
            active
          } = getInventoryListResp?.data?.inventories[0] || {};
          const itemDetails = {
            cost: `${formatAmountSymbol()}${cost}`,
            description,
            itemNumber,
            retail: `${formatAmountSymbol()}${retail}`,
            customerSpecialPrice: customerSpecialPrice ? `${formatAmountSymbol()}${customerSpecialPrice.cPrice}` : "-",
            supplierItemNumber,
            weight,
            quantityInStock,
            units: casePack,
            salesLimit,
            discount,
            active
          };
          selectedItemHandler(itemDetails, undefined, true);
        }
      }
    }
    if (getCustomerInventoryDiscountsResp && prevGetCustomerInventoryDiscountsResp !== getCustomerInventoryDiscountsResp) {
      const { 
        code, 
        pricingType,

      } = getCustomerResp.data.customer;
      setCustomerDetails((prevDetails) => ({
        ...prevDetails,
        isOutOfState,
      }))
      setTableData(prev => {
        let updatedTableData = [...prev];
        getCustomerInventoryDiscountsResp?.data?.inventories.forEach(item => {
          const {discount, specialPrice, priceA, priceB, priceC, priceD, retail } = item;
          if(discount && isGetSalesDisc) {
            updatedTableData = updatedTableData.map(it => {
              if(it.itemNumber === item.itemNumber) {
                const {amount} = it;
                const price = (amount - (amount * discount) / 100).toFixed(2);
                const discountVal = (amount - price).toFixed(2);
                const itemData = {...it, price, discountVal, discount };
                if(specialPrice?.cPrice) {
                  itemData.customerSpecialPrice = specialPrice?.cPrice;
                  delete itemData.codePrice;
                }
                else if(pricingType === "code" && code && (priceA || priceB || priceC || priceD)) {
                  if (code === "A") itemData.codePrice = priceA;
                  else if (code === "B") itemData.codePrice = priceB;
                  else if (code === "C") itemData.codePrice = priceC;
                  else if (code === "D") itemData.codePrice = priceD;
                  delete itemData.customerSpecialPrice;
                }
                else {
                  delete itemData.codePrice;
                  delete itemData.customerSpecialPrice;
                }
                return itemData;
              } else return it;
            });
          } else if(specialPrice?.cPrice) {
            updatedTableData = updatedTableData.map(it => {
              if(it.itemNumber === item.itemNumber) {
                const {amount} = it;
                const price = specialPrice?.cPrice;
                const discountVal = (amount - price).toFixed(2);
                const discount = ((discountVal / amount) * 100).toFixed(2);
                return {...it, price, discountVal, discount, customerSpecialPrice:price, codePrice:undefined };
              } else return it;
            })
          } else if (pricingType === "code" && code && (priceA || priceB || priceC || priceD)) {
            updatedTableData = updatedTableData.map(it => {
              if(it.itemNumber === item.itemNumber) {
                const {amount} = it;
                let price = "";
                if (code === "A") price = priceA;
                else if (code === "B") price = priceB;
                else if (code === "C") price = priceC;
                else if (code === "D") price = priceD;
                const discountVal = (amount - price).toFixed(2);
                const discount = ((discountVal / amount) * 100).toFixed(2);
                return {...it, price, discountVal, discount, codePrice:price, customerSpecialPrice:undefined };
              } else return it;
            });    
          } else {
            updatedTableData = updatedTableData.map(it => {
              if(it.itemNumber === item.itemNumber) {
                const {amount} = it;
                let price = retail;
                const discountVal = (amount - price).toFixed(2);
                const discount = ((discountVal / amount) * 100).toFixed(2);
                return {...it, price, discountVal, discount, codePrice:undefined, customerSpecialPrice:undefined };
              } else return it;
            });
          }
        });
        return updatedTableData;
      })
    }
    if (salesErrorResp && prevSalesErrorResp !== salesErrorResp) {
      const { errors, error, message } = salesErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
      isRouteChangeApiCalled.current = false;
    }
    if (customerErrResp && prevCustomerErrResp !== customerErrResp) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (supplierErrorResp && prevSupplierErrorResp !== supplierErrorResp) {
      const { errors, error, message } = supplierErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (inventoryErrorResp && prevInventoryErrorResp !== inventoryErrorResp) {
      const { errors, error, message } = inventoryErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetCustomerResp = getCustomerResp;
    customerResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    customerResp.current.prevCreateInvoiceResp = createInvoiceResp;
    customerResp.current.prevUpdateInvoiceResp = updateInvoiceResp;
    customerResp.current.prevDeleteInvoiceResp = deleteInvoiceResp;
    customerResp.current.prevGetInvoiceDetailsResp = getInvoiceDetailsResp;
    customerResp.current.prevChangePostedStatusOfInvoiceResp = changePostedStatusOfInvoiceResp;
    customerResp.current.prevCreateCustomerInventoryPriceResp = createCustomerInventoryPriceResp;
    customerResp.current.prevGetInventoryItemResp = getInventoryItemResp;
    customerResp.current.prevDownloadInvoicePdfResp = downloadInvoicePdfResp;
    customerResp.current.prevGetInventoryListResp = getInventoryListResp;
    customerResp.current.prevGetCustomerInventoryDiscountsResp = getCustomerInventoryDiscountsResp;
    customerResp.current.prevSalesErrorResp = salesErrorResp;
    customerResp.current.prevCustomerErrResp = customerErrResp;
    customerResp.current.prevMasterDataErrorResp = masterDataErrorResp;
    customerResp.current.prevSupplierErrorResp = supplierErrorResp;
    customerResp.current.prevInventoryErrorResp = inventoryErrorResp;
  }, [
    getAllCustomersResp,
    getCustomerResp,
    createInvoiceResp,
    updateInvoiceResp,
    deleteInvoiceResp,
    getInvoiceDetailsResp,
    changePostedStatusOfInvoiceResp,
    createCustomerInventoryPriceResp,
    getInventoryItemResp,
    downloadInvoicePdfResp,
    getInventoryListResp,
    getCustomerInventoryDiscountsResp,
    salesErrorResp,
    customerErrResp,
    masterDataErrorResp,
    supplierErrorResp,
    inventoryErrorResp,
  ]);
 
  useEffect(()=>{
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      isOutOfState,
    }))
  },[isOutOfState])



  const downloadInvoicePdfApiCall = (invoiceNumber) =>
    dispatch(
      downloadInvoicePdfAct(generateApiUrl("download_invoice_pdf", { invoiceNumber }), {}, { responseType: "blob" })
    );

  useEffect(() => {
    if (!isBlocked && isCreateInvoiceBtnClicked.current) {
      if (shouldDownloadPdf.current) downloadInvoicePdfApiCall(getMaxInvoiceNoResp?.data?.invoiceNumber);
      else history.push(`/warehousesales/${getMaxInvoiceNoResp?.data?.invoiceNumber}`);
      toast.success("Successfully saved invoice details");
      paymentDetailsValidation.setValues(initialPaymentDetailsValues);
      // history.push(`/warehousesales/${getMaxInvoiceNoResp?.data?.invoiceNumber}`);
      if (showPaymentsModal) {
        togglePaymentsModal();
    }
      isCreateInvoiceBtnClicked.current = false;
    }
  }, [isBlocked,showPaymentsModal ]);

  useEffect(() => {
    if (search === "") setSearchListOptions([]);
  }, [search]);
  useEffect(()=>{
    const totalAmountFixed = Number(totalAmount.toFixed(2));
    setDiscountValue(
      ((Math.abs(totalAmountFixed) * invDiscount) / 100).toFixed(2)
    );
},[totalAmount])
  const getAllCustomersApiCall = useCallback(
    (showAllCustomers, page, download, downloadType="pdf") => {
      let params = {};
      if (showAllCustomers) {
        let params = { page: page || 1, pageSize: 50, orderBy: "company", sort: "asc" };
        dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
        dropdownBtnClicked.current = true;
      } 
      if (download && downloadType === "html") {
        shouldDownloadPdf.current = false;
        isDownloadBtnClicked.current = false;
        isPaymetDetailsSubmitted.current = true;
        params.download = true;
        params.downloadType = downloadType;
        if (id && id !== "new") {
          params.invoiceNumber = id;
        } else {
          params.invoiceNumber = getMaxInvoiceNoResp?.data?.invoiceNumber;
        }
        dispatch(getInvPricingReportPrintAct(generateApiUrl("download_invoice_pdf",{ invoiceNumber: params.invoiceNumber }), params));
      } 
      else if (download) {
        isPaymetDetailsSubmitted.current = true;
        params.download = true;
        shouldDownloadPdf.current = true;

        if (id && id !== "new") {
          params.invoiceNumber = id;
        } else {
          params.invoiceNumber = getMaxInvoiceNoResp?.data?.invoiceNumber;
        }
        dispatch(downloadInvoicePdfAct(generateApiUrl("download_invoice_pdf", { invoiceNumber: params.invoiceNumber }), params, { responseType: "blob" }));
      } 
      
      else if (search) {
        let params = { page: 1, pageSize: 5, search, orderBy: "company", sort: "asc" };
        dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
        dropdownBtnClicked.current = false;
      }
    },
    [search, tableData]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);
  const getInvoiceDetailsApiCall = () =>
    dispatch(getInvoiceDetailsAct(generateApiUrl(`get_invoice_details`, { invoiceNumber: id })));
  useEffect(() => {
    if (id && id === "new") {
      const searchInputElem = document.getElementById("search-item");
      if (searchInputElem) searchInputElem.focus();
      initializeInvoiceDetails();
    } else if (id && id !== "new") getInvoiceDetailsApiCall();
  }, [id]);
  useEffect(() => {
    dispatch(getDriversListAct(generateApiUrl(`get_drivers_list`)));
    dispatch(getSalesmanListAct(generateApiUrl(`get_salesman_list`)));
    dispatch(getMasterDataForSupplierAct(generateApiUrl(`get_master_data_for_supplier`), { category: "paymentTerms" }));
    dispatch(getMasterDataByCategoryAct(generateApiUrl(`get_master_data_by_category`), { category: "shippedVia" }));
  }, []);
  function selectItemCallbck(itemDetails) {
    const { customerNumber, customerName, companyName } = itemDetails;
    setCustomerDetails({ customerNumber, customerName, companyName });
    dispatch(getCustomerAct(generateApiUrl(`get_customer`, { customerNo: customerNumber })));
  }
  function changePostedStatusOfInvoiceHandler() {
    dispatch(
      changePostedStatusOfInvoiceAct(generateApiUrl(`change_posted_status_of_invoice`, { invoiceNumber: id }), {
        posted: status !== "posted",
        invoiceDate: moment(),
      })
    );
  }
  useEffect(() => {
    if (status === "open" && customerDetails.customerNumber) {
      focusLastSelectItemNumberInput();
    }
  }, [customerDetails.customerNumber]);
  useEffect(() => {
    if (!activationModalConfig.show) {
      focusLastSelectItemNumberInput(indexOfCurrentAddedItem.current);
    }
  }, [activationModalConfig.show]);
  
  const removeItem = (index) => {
    setTableData((prev) => {
      const updatedTableData = [...prev];
      updatedTableData.splice(index, 1);
      return updatedTableData;
    });
  };
  const getInventoryListApiCall = useCallback(
    (props) => {
      const { description, itemNumber, page, pageSize, active } = props || {};
      if (showSmartSearchModal ? pageSize : true) {
        let params = {
          page: page || 1,
          pageSize: pageSize || 50,
          source: "POS",
          customerNumber: customerDetails.customerNumber,
          active: active !== undefined ? active : true, 
        };
        if (description) params.description = description;
        if (itemNumber) params.itemNumber = itemNumber;
        dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
      }
    },
    [customerDetails.customerNumber, showSmartSearchModal]
  );
  const callbackAfterGetInventoryListApiCall = useCallback((apiResp, setOptions, pageSize) => {
    const list = [];
    (apiResp?.data?.inventories || [])
      .slice(0, pageSize || 50)
      .forEach(
        ({
          cost,
          description,
          itemNumber,
          retail,
          supplierItemNumber,
          weight,
          quantityInStock,
          active,
          casePack,
          salesLimit,
          discount,
          customerSpecialPrice,
        }) => {
          list.push({
            cost: `${formatAmountSymbol()}${cost}`,
            description,
            itemNumber,
            retail: `${formatAmountSymbol()}${retail}`,
            customerSpecialPrice: customerSpecialPrice ? `${formatAmountSymbol()}${customerSpecialPrice.cPrice}` : "-",
            supplierItemNumber,
            weight,
            active,
            quantityInStock,
            units: casePack,
            salesLimit,
            discount,
          });
        }
      );
    setOptions(list);
  }, []);
  const getInventoryItemApiCall = (itemNumber) => {
    dispatch(getInventoryItemAct(generateApiUrl("get_inventory_item", { itemNumber }), { source: "POS" }));
  };
  const selectedItemHandler = (itemDetails, index, isBarcodeScanned) => {
    // const alreadyAddedItem = tableData.find((item) => item.itemNumber === itemDetails.itemNumber);
    const alreadyAddedItem = tableData.slice(tableData.length - 2).find((item) => item.itemNumber === itemDetails.itemNumber);


    if ((index === undefined || index === tableData.length - 1) && alreadyAddedItem) {
      // const alreadyAddetItemIndex = tableData.findIndex((item) => item.itemNumber === itemDetails.itemNumber);
      const alreadyAddetItemIndex = tableData.length - 2;

      indexOfCurrentAddedItem.current = alreadyAddetItemIndex;
      fieldChangeHndlr("quantity", Number(alreadyAddedItem.quantity) + (Number(itemDetails.quantity) || 1), alreadyAddetItemIndex, true);
      if(isBarcodeScanned) {
        // setTimeout(() => {
        //   const selectitemInputs = document.getElementsByClassName("select-item-number-wrapper");
        //   if(selectitemInputs[alreadyAddetItemIndex + 1]) {
        //     const input = selectitemInputs[alreadyAddetItemIndex + 1].querySelector("input");
        //     if(input) input.value = "";
        //   }
        // }, 200);
      }
    } else
      setTableData((prev) => {
        const updatedTableData = [...prev],
          rowData = { ...updatedTableData[index || prev.length - 1] },
          { itemNumber: prevItemNumber } = rowData,
          {
            itemNumber,
            description,
            retail,
            cost,
            weight,
            active,
            quantityInStock,
            units,
            salesLimit,
            discount: salesDiscount,
            customerSpecialPrice,
            quantity,
          } = itemDetails,
          amount = retail.slice(1),
          price =
            isGetSalesDisc && salesDiscount
              ? (amount - (amount * salesDiscount) / 100).toFixed(2)
              : customerSpecialPrice !== "-"
              ? customerSpecialPrice.slice(1)
              : retail.slice(1),
          discountVal = amount - price,
          discount = isGetSalesDisc && salesDiscount ? salesDiscount : ((discountVal / amount) * 100).toFixed(2);
        updatedTableData.splice(index || prev.length - 1, 1, {
          ...rowData,
          description,
          itemNumber,
          price,
          cost: cost.slice(1),
          amount,
          weight,
          quantity: quantity || 1,
          quantityInStock,
          units,
          salesLimit: salesLimit != 0 && !salesLimit ? "NA" : salesLimit,
          originalDisc: salesDiscount,
          discount,
          discountVal: discountVal.toFixed(2),
          customerSpecialPrice: customerSpecialPrice !== "-" ? customerSpecialPrice.slice(1) : "",
        });
        if (!prevItemNumber) {
          updatedTableData.push(initialTableRowValues);
        }
        if (!active) {
          setActivationModalConfig({
            show: true,
            itemNumber,
            description,
          });
          // itemToBeFocussed.current = "itemNumber";
          if(InactiveModal){
            setTimeout(()=>{
              document.getElementById("okref").focus();
            },300)
          }
          return prev;
        }
        let totalAmt = 0;
        updatedTableData.forEach((item) => {
          totalAmt += item.itemNumber ? item.price * item.quantity : 0;
        });
        //Check If invoice exceeds the total limit
        // if (totalAmt > totalLimit) {
        //   toast.error(`Invoice total cannot exceed ${formatAmountSymbol()}${totalLimit}`);
        //   return prev;
        // }
        //Check If invoice exceeds sales limit of customer
        if (
          !isOverridePasswordEnteredDetails.current.customerSalesLimit &&
          (customerSalesLimit) &&
          totalAmt > customerSalesLimit
        ) {
          toggleOverridePasswordModal();
          isOverridePasswordEnteredDetails.current.overridePasswordFor = "customerSalesLimit";
          setOverrideModalText([
            `The current customer sales limit is ${formatAmountSymbol()}${customerSalesLimit}, to add new item enter the password below:`,
          ]);
          tempTableDataForOverridePassword.current = updatedTableData;
          recentlyAddedItemPos.current = index || prev.length - 1;
          // isRecentItemHasCustSpPrice.current =
          //   customerSpecialPrice !== "-" || (isGetSalesDisc && salesDiscount) ? true : false;
          isRecentItemHasCustSpPrice.current = customerSpecialPrice !== "-" ? true : false;
          tempItemNumber.current = itemNumber;
          return prev;
        } else {
          getInventoryItemApiCall(itemNumber);
          recentlyAddedItemPos.current = index || prev.length - 1;
          // isRecentItemHasCustSpPrice.current =
          //   customerSpecialPrice !== "-" || (isGetSalesDisc && salesDiscount) ? true : false;
          isRecentItemHasCustSpPrice.current = customerSpecialPrice !== "-" ? true : false;
          indexOfCurrentAddedItem.current = index || prev.length - 1;
          setSortBy("");
          return updatedTableData;
        }
      });
  };
  
  const fieldChangeHndlr = (key, value, index, dontFocusLastItemNumberInput) => {
    if (!(!isNaN(Number(value)) && value.length >= 12))
      setTableData((prev) => {
        const updatedTableData = [...prev],
          rowData = { ...updatedTableData[index] };
        updatedTableData.splice(index, 1, { ...rowData, [key]: value });
        if (
          key === "price" &&
          prev[index].price !== value &&
          (Number(value) < Number(rowData.cost) ? permissions["SELL_BELOW_COST"] : true)
        ) {
          if (permissions["SET_CUST_PRICE"]) toggleSetPriceConfirmModal();
          customerPriceModalData.current = { itemNumber: prev[index].itemNumber, newPrice: value };
          updatedTableData.splice(index, 1, {
            ...rowData,
            [key]: Number(value).toFixed(2),
            discount: (((prev[index].amount - value) / prev[index].amount) * 100).toFixed(2),
            discountVal: (prev[index].amount - value).toFixed(2),
          });
        } else if (key === "discount" && prev[index].discount !== value) {
          const { amount } = prev[index];
          updatedTableData.splice(index, 1, {
            ...rowData,
            discount: Number(value).toFixed(2),
            discountVal: ((value * amount) / 100).toFixed(2),
            price: (amount - (value * amount) / 100).toFixed(2),
          });
        } else if (key === "discountVal" && prev[index].discountVal !== value) {
          const { amount } = prev[index];
          updatedTableData.splice(index, 1, {
            ...rowData,
            discountVal: Number(value).toFixed(2),
            discount: ((value / amount) * 100).toFixed(2),
            price: (amount - value).toFixed(2),
          });
        }
        if (key === "quantity" && Number(prev[index].quantity) < Number(value)) {
          let totalAmt = 0;
          updatedTableData.forEach((item) => {
            let amountVal = Number(item.amount) || 0;
            let disPerVal = Number(item.discount) || 0;
            let disVal = (item.amount * disPerVal) / 100;
            totalAmt += item.itemNumber ? (amountVal - disVal) * item.quantity : 0;
          });
          //Check If invoice exceeds the total limit
          // if (totalAmt > totalLimit) {
          //   toast.error(`Invoice total cannot exceed ${formatAmountSymbol()}${totalLimit}`);
          //   return prev;
          // }
          //Check if customers limit and item sales limit both exceeded
          if (
            !isOverridePasswordEnteredDetails.current.itemSalesLimit &&
            prev[index].salesLimit !== "NA" &&
            value > prev[index].salesLimit &&
            !isOverridePasswordEnteredDetails.current.customerSalesLimit &&
            (customerSalesLimit) &&
            totalAmt > customerSalesLimit
          ) {
            toggleOverridePasswordModal();
            isOverridePasswordEnteredDetails.current.overridePasswordFor = "both";
            tempTableDataForOverridePassword.current = updatedTableData;
            setOverrideModalText([
              `1. The sales limit of ${prev[index].salesLimit} for ${rowData.description} has exceeded.`,
              `2. The customer limit of ${formatAmountSymbol()}${customerSalesLimit} has exceeded.`,
              `Please enter password to continue:`,
            ]);
            return prev;
          }
          //Check if item sales limit exceeded
          else if (
            !isOverridePasswordEnteredDetails.current.itemSalesLimit &&
            prev[index].salesLimit !== "NA" &&
            value > prev[index].salesLimit
          ) {
            toggleOverridePasswordModal();
            isOverridePasswordEnteredDetails.current.overridePasswordFor = "itemSalesLimit";
            tempTableDataForOverridePassword.current = updatedTableData;
            setOverrideModalText([
              `1. The sales limit of ${prev[index].salesLimit} for ${rowData.description} has exceeded.`,
              `Please enter password to continue:`,
            ]);

            return prev;
          }
          //Check If invoice exceeds sales limit of customer
          else if (
            !isOverridePasswordEnteredDetails.current.customerSalesLimit &&
            (customerSalesLimit) &&
            totalAmt > customerSalesLimit
          ) {
            toggleOverridePasswordModal();
            isOverridePasswordEnteredDetails.current.overridePasswordFor = "customerSalesLimit";
            tempTableDataForOverridePassword.current = updatedTableData;
            setOverrideModalText([
              `1. The customer limit of ${formatAmountSymbol()}${customerSalesLimit} has exceeded.`,
              `Please enter password to continue:`,
            ]);
            return prev;
          } else {
            if (!dontFocusLastItemNumberInput) isFocusLastItemNumberInput.current = true;
            setSortBy("");
            return updatedTableData;
          }
        }
        //Check if user is allowed to sel below cost
        else if (key === "price" && Number(value) < Number(rowData.cost) && !permissions["SELL_BELOW_COST"]) {
          toast.error("You are not allowed to sell below cost");
          return prev;
        } else {
          if (!dontFocusLastItemNumberInput) isFocusLastItemNumberInput.current = true;
          return updatedTableData;
        }
      });
  };
  const createInvoiceHandler = (posted, isRouteChanged) => {
    if (isRouteChanged ? true : id === "new" ? isPaymetDetailsSubmitted.current || isBlocked : true) {
      let cgstValue = cgst;
      const payload = generatePayload(
        tableData,
        customerDetails.customerNumber,
        invDetailsValidation.values,
        paymentDetailsValidation.values,
        totalWeight,
        totalItems,
        totalPaid,
        totalPaid - totalAllocated,
        adjustInBalance,
        tax,
        freight,
        discount,
        discountValue,
        totalAmount,
        prevBalance,
        totalSalesDiscountValue,
        other,
        sgst, 
        cgst,
        igst,
        cgstValue,
        invDiscount
      );
      if (id === "new")
        dispatch(createInvoiceAct(generateApiUrl("create_invoice"), { ...payload, posted }));
      else
        dispatch(
          updateInvoiceAct(generateApiUrl("update_invoice", { invoiceNumber: id }), {
            ...payload,
            posted,
          })
        );
    } else toast.error("Please provide payment details");
  };
  const handleDeleteInvoice = () => {
    if (id) {
      dispatch(deleteInvoiceAct(generateApiUrl("delete_invoice"), { invoiceNumbers: [id] }));
    }
  };
  const handleSetCustomerPrice = () => {
    const { itemNumber, newPrice } = customerPriceModalData.current;
    dispatch(
      createCustomerInventoryPriceAct(generateApiUrl(`create_customer_inventory_price`, { itemNumber }), {
        customers: [
          {
            customerNumber: customerDetails.customerNumber,
            newPrice,
          },
        ],
        "source": "POS"
      })
    );
  };
  const openPurchaseHistoryModal = ({ itemNumber, description }) => {
    setPurchaseHistoryItem({ itemNumber, description });
    togglePurchaseHistoryModal();
  };
  const initializeInvoiceDetails = () => {
    invDetailsValidation.resetForm();
    paymentDetailsValidation.resetForm();
    setTableData([{ ...initialTableRowValues }]);
    setSearch("");
    setCustomerDetails({
      customerNumber: "",
      customerName: "",
      companyName: "",
    });
    setDiscount("");
    setDiscountValue("");
    setInvDiscount("");
    setTax("");
    setFreight("");
    setOther("");
    setSgstPercentageParent(0);
    setCgstPercentageParent(0);
    setIgstPercentageParent(0);
    setStatus("open");
    setPrevBalance(0);
    setIsSalesDisc(false);

    dispatch(getMaxInvoiceNoAct(generateApiUrl(`get_max_invoice_no`)));
  };
  const isReturnInvoice = useMemo(() => {
    let returnInvoice = false;
    tableData.forEach((item) => {
      if (item.quantity < 0) returnInvoice = true;
    });
    return returnInvoice;
  }, [tableData]);
  const columns = useMemo(
    () =>
      generateColumns(
        removeItem,
        getInventoryListApiCall,
        callbackAfterGetInventoryListApiCall,
        selectedItemHandler,
        fieldChangeHndlr,
        openPurchaseHistoryModal,
        status,
        id,
        isReturnInvoice,
        tableData.length,
        permissions
      ),
    [tableData.length, status, getInventoryListApiCall, isGetSalesDisc]
  );
  const totalItems = useMemo(() => {
    let total = 0;
    tableData.forEach((item) => (total += Number(item.quantity) || 0));
    return total;
  }, [tableData]);
  const totalWeight = useMemo(() => {
    let total = 0;
    tableData.forEach((item) => (total += Number(item.quantity) * item.weight || 0));
    return total;
  }, [tableData]);

  const totalPaid = useMemo(() => {
    let total = 0;
    paymentDetailsValidation.values.payments.map((item) => (total += Number(item.amountPaid)));
    return total;
  }, [paymentDetailsValidation.values.payments]);
  const totalAllocated = useMemo(() => {
    let total = 0;
    paymentDetailsValidation.values.payments.map((item) => (total += Number(item.amountAlloc)));
    return total;
  }, [paymentDetailsValidation.values.payments]);
  const totalSalesDiscountValue = useMemo(() => {
    let total = 0;
    tableData.forEach((item) => (total += Number(item.discountVal || 0) * item.quantity));
    return total;
  }, [tableData]);
  const avgSalesDiscount = useMemo(() => {
    let totalAmount = 0;
    tableData.forEach((item) => (totalAmount += Number(item.amount) * item.quantity));
    return totalAmount ? (totalSalesDiscountValue / totalAmount) * 100 : 0;
  }, [totalSalesDiscountValue, totalAmount]);

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    const maxDisc = Number(permissions["INV_MAX_DISC"]);
    const totalAmountFixed = Number(totalAmount.toFixed(2));
    if (name === "discount" && value >= 0 && value <= 100) {
      if (
        (maxDisc || maxDisc === 0 ? (maxDisc > totalAmountFixed ? totalAmountFixed : maxDisc) : totalAmountFixed) >=
        Number((Math.abs(totalAmountFixed) * value) / 100).toFixed(2)
      ) {
        setDiscount(value);
        setDiscountValue(((Math.abs(totalAmountFixed) * value) / 100).toFixed(2));
      }
    } else if (name === "discountValue" && value >= 0 && value <= Math.abs(totalAmountFixed)) {
      if (
        (maxDisc || maxDisc === 0 ? (maxDisc > totalAmountFixed ? totalAmountFixed : maxDisc) : totalAmountFixed) >=
        value
      ) {
        setDiscountValue(value);
        setInvDiscount(((value / Math.abs(totalAmountFixed)) * 100).toFixed(2))
        setDiscount(((value / Math.abs(totalAmountFixed)) * 100).toFixed(2));
      }
    }else if (
      name === "invDiscount" &&
      value >= 0 &&
      value <= 100
    ) {
        setInvDiscount(value);
        setDiscountValue(((value * Math.abs(totalAmountFixed)) / 100).toFixed(2))
        setDiscount(Number(value).toFixed(2));
    }
  };
  const handleGetSalesDiscountChange = (e) => {
    setIsSalesDisc(e.target.checked);
    setTableData((prev) => {
      const updatedTableData = [...prev];
      const newTableData = updatedTableData.map((item) => {
        let discount, discountVal, price;
        if (e.target.checked) {
          if (item.originalDisc) {
            discount = item.originalDisc;
            discountVal = (item.amount * discount) / 100;
            price = item.amount - discountVal;
          } else if (item.customerSpecialPrice) {
            price = item.customerSpecialPrice;
            discountVal = item.amount - price;
            discount = (discountVal / item.amount) * 100;
          } else if (item.codePrice) {
            price = item.codePrice;
            discountVal = item.amount - price;
            discount = (discountVal / item.amount) * 100;
          } else {
            discount = "";
            discountVal = "";
            price = item.amount;
          }
        } else {
          if (item.customerSpecialPrice) {
            price = item.customerSpecialPrice;
            discountVal = item.amount - price;
            discount = (discountVal / item.amount) * 100;
          } else if (item.codePrice) {
            price = item.codePrice;
            discountVal = item.amount - price;
            discount = (discountVal / item.amount) * 100;
          } else {
            discount = "";
            discountVal = "";
            price = item.amount;
          }
        }
        return {
          ...item,
          discount: (discount || 0).toFixed(2),
          discountVal: (discountVal || 0).toFixed(2),
          price: Number(price || 0).toFixed(2),
        };
      });
      return newTableData;
    });
  };

  useEffect(() => {
  if (!showSmartSearchModal && !isSortByClicked.current) {
    if (isFocusLastItemNumberInput.current) {
      focusLastSelectItemNumberInput();
      isFocusLastItemNumberInput.current = false;
    } else {
      focusQuantityNumberInput(indexOfCurrentAddedItem.current);
    }
  }
  else if(showSmartSearchModal){
   const elements = document.getElementsByClassName("invoice-table")
    
    elements[0].scrollTop=elements[0].scrollHeight;
  }
}, [showSmartSearchModal, isSortByClicked.current, tableData]);
  

  const f5Handler = (e) => {
    const { key } = e;
    if (key === "F5") {
      e.preventDefault();
      if (id === "new" || status === "open") handelOpenPaymentsModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", f5Handler);
    return () => {
      window.removeEventListener("keydown", f5Handler);
    };
  }, [id, status, customerDetails.customerNumber]);

  const handleTaxChange = (e) => {
    const { value } = e.target;
    const invTotal = totalAmount + Number(value) + Number(freight) + Number(other) - discountValue;
    setTax(value);
    // if (invTotal <= totalLimit) setTax(value);
    // else toast.error(`Invoice total cannot exceed ${formatAmountSymbol()}${totalLimit}`);
  };

  const handleFreightChange = (e) => {
    const { value } = e.target;
    const invTotal = totalAmount + Number(tax) + Number(value) + Number(other) - discountValue;
    setFreight(value);
    // if (invTotal <= totalLimit) setFreight(value);
    // else toast.error(`Invoice total cannot exceed ${formatAmountSymbol()}${totalLimit}`);
  };

  const invoiceResetConfirmHandler = () => {
    createInvoiceHandler(false);
  };

  const invoiceRejectConfirmHandler = () => {
    initializeInvoiceDetails();
    toggleInvoiceResetConfirmModal();
  };
  const sortByChngHndlr = (e) => {
    const { value } = e.target;
    setSortBy(value);
    if (value === "Item Number") {
      setTableData([
        ...tableData.slice(0, tableData.length - 1).sort((a, b) => a.itemNumber - b.itemNumber),
        tableData[tableData.length - 1],
      ]);
    } else if (value === "Description") {
      setTableData([
        ...tableData.slice(0, tableData.length - 1).sort((a, b) => a.description.localeCompare(b.description)),
        tableData[tableData.length - 1],
      ]);
    } else if (value === "Quantity") {
      setTableData([
        ...tableData.slice(0, tableData.length - 1).sort((a, b) => a.quantity - b.quantity),
        tableData[tableData.length - 1],
      ]);
    } else if (value === "Amount") {
      setTableData([
        ...tableData.slice(0, tableData.length - 1).sort((a, b) => a.amount - b.amount),
        tableData[tableData.length - 1],
      ]);
    } else if (value === "Price") {
      setTableData([
        ...tableData.slice(0, tableData.length - 1).sort((a, b) => a.price - b.price),
        tableData[tableData.length - 1],
      ]);
    } else if (value === "Total") {
      setTableData([
        ...tableData.slice(0, tableData.length - 1).sort((a, b) => a.price * a.quantity - b.price * b.quantity),
        tableData[tableData.length - 1],
      ]);
    }
  };
 useEffect(() => {

  if((id === 'new') && (customerDetails.isOutOfState)){
    setIgst(18);
    setTax(0);
  }
 },[customerDetails.isOutOfState,isOutOfState])

  return (
    <React.Fragment>
    {/* <ToastContainer /> */}
    <div className="sale-wh-wrapper whbrand-bg whbrand-text-color" style={{fontWeight:'600'}}>
      <Container fluid >
        {history.location?.state?.redirected && (
          <div className="d-flex mb-3 px-4">
            <div
              className="d-flex align-items-center wh-link-color-green fs-5"
              style={{ cursor: "pointer" }}
              onClick={history.goBack}
            >
              <i className="ri-arrow-left-line me-1"></i>
              <div className="mb-0 text-decoration-underline">Go Back</div>
            </div>
          </div>
        )}
        <Row className="px-4 pt-0 whbrand-bg">
          <Col xs={7} className="px-0">
            <Row>
              <Col xs={6}>
                <SearchCustomerBox
                  search={search}
                  setSearch={setSearch}
                  searchListOptions={searchListOptions}
                  setSearchListOptions={setSearchListOptions}
                  selectItemCallbck={selectItemCallbck}
                  status={status}
                  getAllCustomersApiCall={getAllCustomersApiCall}
                  currentCustomerListPage={currentCustomerListPage}
                  callbackOnCustomerListApiResp={callbackOnCustomerListApiResp}
                  outerDivClassName='py-1'
                  boldText={boldTextCust}
                />
              </Col>
              <Col xs={6}>
              <SalesActions
                  handelOpenPaymentsModal={handelOpenPaymentsModal}
                  history={history}
                  toggleDeleteModal={toggleDeleteModal}
                  initializeInvoiceDetails={initializeInvoiceDetails}
                  id={id}
                  createInvoiceHandler={createInvoiceHandler}
                  getAllCustomersApiCall={getAllCustomersApiCall}
                  getInvPricingReportLoading={getInvPricingReportLoading}
                  getInvoiceDetailsApiCall={getInvoiceDetailsApiCall}
                  status={status}
                  customerDetails={customerDetails}
                  downloadInvoicePdfApiCall={downloadInvoicePdfApiCall}
                  toggleInvoiceResetConfirmModal={toggleInvoiceResetConfirmModal}
                  isBlocked={isBlocked}
                  permissions={permissions}
                  outerDivClassName={'pt-1 pb-0'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="d-flex align-items-center py-1" >
                  <div className="d-flex align-items-center me-2">
                    <Label htmlFor="userrole" 
                    className="form-label mb-0 me-2 mb-0 text-nowrap"
                    style={{fontWeight:600}}
                    >
                      Get Sales/Discount
                    </Label>
                    <Input
                      className="form-check-input mt-0"
                      type="checkbox"
                      checked={isGetSalesDisc}
                      onChange={handleGetSalesDiscountChange}
                      disabled={status === "posted"}
                    />
                  </div>
                  <div className="border py-1 px-3 text-muted" style={{fontWeight:'bold'}}>
                    <span className="me-5">Discount</span>
                    <span className="me-5">{formatAmount(totalSalesDiscountValue.toFixed(2))}</span>
                    <span className="">{avgSalesDiscount.toFixed(2)}%</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InvoiceDetails
                  invDetailsValidation={invDetailsValidation}
                  status={status}
                  getSalesmanListResp={getSalesmanListResp}
                  getMasterDataForSupplierResp={getMasterDataForSupplierResp}
                  getMasterDataByCategoryResp={getMasterDataByCategoryResp}
                  getDriversListResp={getDriversListResp}
                  totalWeight={totalWeight}
                  totalItems={totalItems}
                  thdStyle={thdStyle}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <Row className="h-100">
              <Col  xs={5} className="d-flex flex-column justify-content-start align-items-center">
                <Row>
                  <Col xs={12} className="d-flex flex-column justify-content-start align-items-center">
                    <InvoiceStatusInfo
                      id={id}
                      status={status}
                      getMaxInvoiceNoResp={getMaxInvoiceNoResp}
                      paid={paid}
                      totalAmount={totalAmount}
                      tax={tax}
                      other={other}
                      freight={freight}
                      discountValue={discountValue}
                      totalPaidOnInvoice={totalPaidOnInvoice}
                      toggleOpenInvoiceConfirm={toggleOpenInvoiceConfirm}
                      permissions={permissions}
                    />
                  </Col>
                
                  <Col className="pe-5 mt-3 mb-2" xs={12}>
                    <div className="d-flex justify-content-end" >
                      <div className="d-flex align-items-center">
                        <Label className="text-nowrap m-0 me-2" style={{fontWeight:600}}>Sort By : </Label>
                        <Input 
                          type="select"
                          className="m-0 form-select"
                          value={sortBy}
                          onChange={sortByChngHndlr}
                          onClick={() => (isSortByClicked.current = true)}
                          style={{fontWeight:600}}
                        >
                          <option value="">Select</option>
                          <option>Item Number</option>
                          <option>Description</option>
                          <option>Quantity</option>
                          <option>Amount</option>
                          <option>Price</option>
                          <option>Total</option>
                        </Input>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={7}>
                <InvoiceCalculationsSidebar
                  id={id}
                  status={status}
                  fBalance={fBalance}
                  prevBalance={prevBalance}
                  totalAmount={totalAmount}
                  discountValue={discountValue}
                  isOutOfState={customerDetails.isOutOfState}
                  setDiscountValue={setDiscountValue}
                  discount={discount}
                  setDiscount={setDiscount}
                  handleDiscountChange={handleDiscountChange}
                  tax={tax}
                  setTax={setTax}
                  handleTaxChange={handleTaxChange}
                  freight={freight}
                  setFreight={setFreight}
                  setSgst={setSgst}
                  setCgst={setCgst}
                  setIgst={setIgst}
                  setSgstPercentageParent={setSgstPercentageParent}
                  setCgstPercentageParent={setCgstPercentageParent}
                  setIgstPercentageParent={setIgstPercentageParent}
                  sgstPercentage={sgst}
                  cgstPercentage={cgst}
                  igstPercentage={igst} 
                  handleFreightChange={handleFreightChange}
                  permissions={permissions}
                  other={other}
                  setOther={setOther}
                  tdStyle={tdStyle}
                  setInvDiscount={setInvDiscount}
                  invDiscount={invDiscount}
                />
          </Col>
              
            </Row>
          </Col>
        </Row>
        <Row className='pe-2 my-0 mt-1 whbrand-bg'
        // style={{ width:'100%', position: "fixed", zIndex: "99",height:'maxcontent'}}
        >
        <Col className="px-4 pe-4" style={{fontWeight:600,}} xs={12}>
          <div ref={tableRef} style={{ zIndex: 1}} >
              <WhTableContainer
                columns={columns}
                data={customerDetails.customerNumber ? tableData : []}
                customPageSize={7}
                className="custom-header-css"
                divClass="table-responsive invoice-table whbrand-table table-container-pos"
                tableClass="align-middle table-bordered border border-dark"
                theadClass="sticky-top"
                // theadClass={thsticky}
                thClass={"py-1 sticky-top px-2 whbrand-table-border whbrand-th-bg"}
                // tdClass="p-0 px-2"
                tableHover={false}
                tableStyles={tableStyles}
                thStyle={thStyle}
              />
          </div>
              
        </Col>
          
        </Row>
      <Row>
        <Col xs={12}>
          <FooterWH />
        </Col>
      </Row>
      </Container>
    </div>
    {/* <div style={{marginTop: '5px'}}>
      <FooterWH />
    </div> */}

      <PaymentsModal isOpen={showPaymentsModal} toggle={togglePaymentsModal}>
        <PaymentsModalBody
          totalAmount={totalAmount}
          tax={tax}
          freight={freight}
          other={other}
          subtotal={subtotal}
          sgst={sgst}
          cgst={cgst}
          igst={igst}
          totalDiscount={discountValue}
          customerDetails={customerDetails}
          getMaxInvoiceNoResp={getMaxInvoiceNoResp}
          id={id}
          isBillToBill={isBillToBill}
          validation={paymentDetailsValidation}
          togglePaymentsModal={togglePaymentsModal}
          prevBalance={prevBalance}
          adjustInBalance={adjustInBalance}
          setAdjustInBalance={setAdjustInBalance}
          totalPaid={totalPaid}
          change={totalPaid - totalAllocated}
          totalAllocated={totalAllocated}
          status={status}
          isDownloadBtnClicked={isDownloadBtnClicked}
          shouldDownloadPdf={shouldDownloadPdf}
          paymentTypes={paymentTypes}
          isCreditHold={isCreditHold}
          currentBalance={currentBalance}
          customerCreditLimit={customerCreditLimit}
          toast={toast}
          permissions={permissions}
        />
      </PaymentsModal>

    <PurcahseHistoryModal
      isOpen={showPurchaseHistoryModal}
      toggle={togglePurchaseHistoryModal}
      purchaseHistoryItem={purchaseHistoryItem}
      customerDetails={customerDetails}
      dispatch={dispatch}
      generateApiUrl={generateApiUrl}
      getSalesOfGivenItemResp={getSalesOfGivenItemResp}
    />
    <OpenInvoiceConfirmModal
      isOpen={showOpenInvoiceConfirm}
      toggle={toggleOpenInvoiceConfirm}
      changePostedStatusOfInvoiceHandler={changePostedStatusOfInvoiceHandler}
      status={status}
      id={id}
    />
    <PopUpMsgModal
      showPopUpMsgModal={showPopUpMsgModal}
      togglePopUpMsgModal={togglePopUpMsgModal}
      popMemo={popMemo}
    />
    <OverridePasswordModal
      isOpen={showOverridePasswordModal}
      toggle={toggleOverridePasswordModal}
      toggleOverridePasswordModal={toggleOverridePasswordModal}
      tempTableDataForOverridePassword={tempTableDataForOverridePassword}
      setTableData={setTableData}
      isOverridePasswordEnteredDetails={isOverridePasswordEnteredDetails}
      getInventoryItemApiCall={getInventoryItemApiCall}
      tempItemNumber={tempItemNumber}
      overrideModaltext={overrideModaltext}
    />
    <Draggable cancel=".cancledrag">
    <Modal id="smartSearchModal" isOpen={showSmartSearchModal} toggle={toggleSmartSearchModal} centered size="xl">
    <F12CloseModalListener onClose={toggleSmartSearchModal} />
      <ModalHeader className="py-2 px-3 border-bottom mb-2" toggle={toggleSmartSearchModal}>
        Select Inventory Item
      </ModalHeader>
      <ModalBody className="px-0 pt-0 cancledrag">
        <SmartSearch
          getInventoryListApiCall={getInventoryListApiCall}
          callbackAfterGetInventoryListApiCall={callbackAfterGetInventoryListApiCall}
          selectedItemHandler={selectedItemHandler}
          toast={toast}
          loading={getInventoryListLoading}
        />
      </ModalBody>
    </Modal>
    </Draggable>

    <InactiveModal
     show={activationModalConfig.show}
     onCloseClick={closeItemActivationModal}
     title={`${activationModalConfig.itemNumber}-${activationModalConfig.description}`}
     confirmationText={"The item is inactive... "}
    />
    
    {/* <CustomerAddrMsgModal
      showCustomerAddrMsgModal={showCustomerAddrMsgModal}
      toggleCustomerAddrMsgModal={toggleCustomerAddrMsgModal}
      customerDetails={customerDetails}
    /> */}
    <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteInvoice} />
    <ConfirmationModal
      onConfirmClick={handleSetCustomerPrice}
      onCloseClick={toggleSetPriceConfirmModal}
      show={showSetPriceConfirmModal}
      type="approve"
      title="Set Customer Price"
      confirmationText="Do you want to permanently set this price for current customer?"
    />
    <PaymentsAnywhere isPaymentAnywhereModelOpen={isPaymentAnywhereModelOpen} />
    <InvoiceResetConfirm
      isOpen={showInvoiceResetConfirmModal}
      toggleModal={toggleInvoiceResetConfirmModal}
      confirmHandlder={invoiceResetConfirmHandler}
      rejectHandler={invoiceRejectConfirmHandler}
    />
    <RouteChangeConfirmation
      isBlocked={isBlocked}
      title="Leave Page?"
      content="You have unsaved changes. Are you sure you want to leave this page?"
      saveDetailsHndlr={() => {
        isRouteChangeApiCalled.current = true;
        createInvoiceHandler(false);
      }}
      routeChangeApiResp={id === "new" ? createInvoiceResp : updateInvoiceResp}
      routeChangeApiErrResp={salesErrorResp}
      toast={toast}
    />
    <ApiLoader loading={salesApiLoading} />
  </React.Fragment>
  );
}
export default withPermission(SalesWH, "POS_MENU");
