import reportsconstants from "./reportsconstants";

const initialState = {
  getSuppliersReportResp: undefined,
  getSuppliersReportLoading: false,
  getPoAnalysisReportResp: undefined,
  getPoAnalysisReportLoading: false,
  getInvPricingReportResp: undefined,
  getInvPricingReportPrintResp: undefined,
  getInvPricingReportLoaidng: false,
  getInvPricingStatsResp: undefined,
  getCustomersReportResp: undefined,
  getCustomersReportLoading: false,
  getCustomersStatsResp: undefined,
  getInvPricingUpdateColsResp: undefined,
  updateInvPricingResp: undefined,
  getInvPriceCodeResp: undefined,
  updateInvPriceCodeResp: undefined,
  getCustomerRouteReportResp: undefined,
  getPaymentsReportResp: undefined,
  getPaymentsReportLoading: false,
  getSalesHistoryReportResp: undefined,
  getSalesHistoryReportLoading: false,
  getSalesHighestResp: undefined,
  getCustomerBalanceStatementResp: undefined,
  getCustomerBalanceStatementLoading: false,
  getPurchasesReportResp: undefined,
  getPurchasesReportLoading: false,
  getSalesReportItemsResp: undefined,
  getSalesReportItemsLoading: false,
  getInvPurchasesReportResp: undefined,
  getInvSalesReportResp: undefined,
  getReturnSalesReportResp: undefined,
  getReturnSalesReportLoading: false,
  getDriversReportResp: undefined,
  getMsaFileReportResp: undefined,
  getShopifySellsReportResp: undefined,
  getInventoryDetectionNewKindReportResp: undefined,
  getInventoryDetectionNewKindReportLoading: undefined,
  getInventoryDetectionNewBooReportResp: undefined,
  getInventoryDetectionNewBooReportLoading: undefined,
  getInventoryDetectionAmazonReportResp: undefined,
  getInventoryDetectionAmazonReportLoading: undefined,
  getInventoryAdjustmentReportResp: undefined,
  getInventoryAdjustmentReportLoading: undefined,
  getDriversReportLoading: false,
  getMsaFileReportLoading: false,
  getShopifySellsReportLoading: false,
  getShopifyReportResp: undefined,
  getShopifyReportLoading: false,
  reportsErrorResp: undefined,
};

export default function reports(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case reportsconstants.GET_SUPPLIERS_REPORT_SUCCESS:
      return { ...state, getSuppliersReportResp: payload };
    case reportsconstants.CHANGE_SUPPLIERS_REPORT_LOADING_STATUS:
      return { ...state, getSuppliersReportLoading: payload };
    case reportsconstants.GET_PO_ANALYSIS_REPORT_SUCCESS:
      return { ...state, getPoAnalysisReportResp: payload };
    case reportsconstants.CHANGE_PO_ANALYSIS_REPORT_LOADING_STATUS:
      return { ...state, getPoAnalysisReportLoading: payload };

    case reportsconstants.GET_INV_PRICING_REPORT_SUCCESS:
      return { ...state, getInvPricingReportResp: payload };

    case reportsconstants.GET_INV_PRICING_REPORT_PRINT_SUCCESS:
      return { ...state, getInvPricingReportPrintResp: {html:payload} };

    case reportsconstants.GET_INVENTORY_DETECTION_NEWKIND_REPORT_SUCCESS:
      return { ...state, getInventoryDetectionNewKindReportResp: payload };

    case reportsconstants.GET_INVENTORY_DETECTION_NEWBOO_REPORT_SUCCESS:
      return { ...state, getInventoryDetectionNewBooReportResp: payload };

    case reportsconstants.GET_INVENTORY_DETECTION_AMAZON_REPORT_SUCCESS:
      return { ...state, getInventoryDetectionAmazonReportResp: payload };

    case reportsconstants.GET_INVENTORY_ADJUSTMENT_REPORT_SUCCESS:
      return { ...state, getInventoryAdjustmentReportResp: payload };

    case reportsconstants.GET_ECOMMERCE_ORDERS_REPORT_SUCCESS:
      return { ...state, getShopifyReportResp: payload };
    case reportsconstants.CHANGE_ECOMMERCE_ORDERS_REPORT_LOADING_STATUS:
      return { ...state, getShopifyReportLoading: payload };

    case reportsconstants.CHANGE_GET_INV_PRICING_REPORT_LOADING_STATUS:
      return { ...state, getInvPricingReportLoading: payload };
    case reportsconstants.GET_INV_PRICING_STATS_SUCCESS:
      return { ...state, getInvPricingStatsResp: payload };
    case reportsconstants.GET_CUSTOMERS_REPORT_SUCCESS:
      return { ...state, getCustomersReportResp: payload };
    case reportsconstants.CHANGE_GET_CUSTOMERS_REPORT_LOADING_STATUS:
      return { ...state, getCustomersReportLoading: payload };
    case reportsconstants.GET_CUSTOMERS_STATS_SUCCESS:
      return { ...state, getCustomersStatsResp: payload };
    case reportsconstants.GET_INV_PRICING_UPDATE_COLS_SUCCESS:
      return { ...state, getInvPricingUpdateColsResp: payload };
    case reportsconstants.UPDATE_INV_PRICING_SUCCESS:
      return { ...state, updateInvPricingResp: payload };
    case reportsconstants.GET_INV_PRICE_CODE_SUCCESS:
      return { ...state, getInvPriceCodeResp: payload };
    case reportsconstants.UPDATE_INV_PRICE_CODE_SUCCESS:
      return { ...state, updateInvPriceCodeResp: payload };
    case reportsconstants.GET_CUSTOMER_ROUTE_REPORT_SUCCESS:
      return { ...state, getCustomerRouteReportResp: payload };
    case reportsconstants.GET_PAYMENTS_REPORT_SUCCESS:
      return { ...state, getPaymentsReportResp: payload };
    case reportsconstants.CHANGE_GET_PAYMENTS_REPORT_LOADING_STATUS:
      return { ...state, getPaymentsReportLoading: payload };
    case reportsconstants.GET_SALES_HISTORY_REPORT_SUCCESS:
      return { ...state, getSalesHistoryReportResp: payload };
    case reportsconstants.CHANGE_GET_SALES_HISTORY_REPORT_LOADING_STATUS:
      return { ...state, getSalesHistoryReportLoading: payload };
    case reportsconstants.GET_SALES_HIGHEST_SUCCESS:
      return { ...state, getSalesHighestResp: payload };
    case reportsconstants.GET_CUSTOMER_BALANCE_STATEMENT_SUCCESS:
      return { ...state, getCustomerBalanceStatementResp: payload };
    case reportsconstants.CHANGE_GET_CUSTOMER_BALANCE_STATEMENT_LOADING_STATUS:
      return { ...state, getCustomerBalanceStatementLoading: payload };
    case reportsconstants.GET_PURCHASES_REPORT_SUCCESS:
      return { ...state, getPurchasesReportResp: payload };
    case reportsconstants.CHANGE_GET_PURCHASES_REPORT_LOADING_STATUS:
      return { ...state, getPurchasesReportLoading: payload };
    case reportsconstants.GET_SALES_REPORT_ITEMS_SUCCESS:
      return { ...state, getSalesReportItemsResp: payload };
    case reportsconstants.CHANGE_GET_SALES_REPORT_ITEMS_LOADING_STATUS:
      return { ...state, getSalesReportItemsLoading: payload };
    case reportsconstants.GET_INV_PURCHASES_REPORT_SUCCESS:
      return { ...state, getInvPurchasesReportResp: payload };
    case reportsconstants.GET_INV_SALES_REPORT_SUCCESS:
      return { ...state, getInvSalesReportResp: payload };
    case reportsconstants.GET_RETURN_SALES_REPORT_SUCCESS:
      return { ...state, getReturnSalesReportResp: payload };
    case reportsconstants.CHANGE_GET_RETURN_SALES_REPORT_LOADING_STATUS:
      return { ...state, getReturnSalesReportLoading: payload };
    case reportsconstants.GET_DRIVERS_REPORT_SUCCESS:
      return { ...state, getDriversReportResp: payload };
    case reportsconstants.CHANGE_GET_DRIVERS_REPORT_LOADING_STATUS:
      return { ...state, getDriversReportLoading: payload };
    case reportsconstants.GET_MSA_FILE_REPORT_SUCCESS:
      return { ...state, getMsaFileReportResp: payload };
    case reportsconstants.GET_SHOPIFY_SELL_REPORT_SUCCESS:
      return { ...state, getShopifySellsReportResp: payload };
    case reportsconstants.CHANGE_GET_MSA_FILE_REPORT_LOADING_STATUS:
      return { ...state, getMsaFileReportLoading: payload };
    case reportsconstants.CHANGE_GET_SHOPIFY_SELL_REPORT_LOADING_STATUS:
      return { ...state, getShopifySellsReportLoading: payload };

    case reportsconstants.CHANGE_GET_INVENTORY_ADJUSTMENT_REPORT_LOADING_STATUS:
      return { ...state, getInventoryAdjustmentReportLoading: payload };

    case reportsconstants.CHANGE_GET_INVENTORY_DETECTION_NEWKIND_REPORT_LOADING_STATUS:
      return { ...state, getInventoryDetectionNewKindReportLoading: payload };

    case reportsconstants.CHANGE_GET_INVENTORY_DETECTION_NEWBOO_REPORT_LOADING_STATUS:
      return { ...state, getInventoryDetectionNewBooReportLoading: payload };

    case reportsconstants.CHANGE_GET_INVENTORY_DETECTION_AMAZON_REPORT_LOADING_STATUS:
      return { ...state, getInventoryDetectionAmazonReportLoading: payload };

    case reportsconstants.GET_SUPPLIERS_REPORT_FAIL:
    case reportsconstants.GET_PO_ANALYSIS_REPORT_FAIL:
    case reportsconstants.GET_INV_PRICING_REPORT_FAIL:
    case reportsconstants.GET_INV_PRICING_REPORT_PRINT_FAIL:
    case reportsconstants.GET_INV_PRICING_STATS_FAIL:
    case reportsconstants.GET_CUSTOMERS_REPORT_FAIL:
    case reportsconstants.GET_CUSTOMERS_STATS_FAIL:
    case reportsconstants.GET_INV_PRICING_UPDATE_COLS_FAIL:
    case reportsconstants.UPDATE_INV_PRICING_FAIL:
    case reportsconstants.GET_INV_PRICE_CODE_FAIL:
    case reportsconstants.UPDATE_INV_PRICE_CODE_FAIL:
    case reportsconstants.GET_CUSTOMER_ROUTE_REPORT_FAIL:
    case reportsconstants.GET_PAYMENTS_REPORT_FAIL:
    case reportsconstants.GET_SALES_HISTORY_REPORT_FAIL:
    case reportsconstants.GET_SALES_HIGHEST_FAIL:
    case reportsconstants.GET_CUSTOMER_BALANCE_STATEMENT_FAIL:
    case reportsconstants.GET_PURCHASES_REPORT_FAIL:
    case reportsconstants.GET_SALES_REPORT_ITEMS_FAIL:
    case reportsconstants.GET_INV_PURCHASES_REPORT_FAIL:
    case reportsconstants.GET_INV_SALES_REPORT_FAIL:
    case reportsconstants.GET_INVENTORY_DETECTION_NEWKIND_REPORT_FAIL:
    case reportsconstants.GET_INVENTORY_DETECTION_NEWBOO_REPORT_FAIL:
    case reportsconstants.GET_INVENTORY_DETECTION_AMAZON_REPORT_FAIL:
    case reportsconstants.GET_INVENTORY_ADJUSTMENT_REPORT_FAIL:
    case reportsconstants.GET_RETURN_SALES_REPORT_FAIL:
    case reportsconstants.GET_ECOMMERCE_ORDERS_REPORT_FAIL:
    case reportsconstants.GET_DRIVERS_REPORT_FAIL:
      return { ...state, reportsErrorResp: payload };
    default:
      return state;
  }
}
