export const tabsOption = [
    {
        id: "invoice_edit_report",
        name: "Invoice Edit / Void Report"
    },
    {
        id: "msa_report",
        name: "MSA Report"
    },
    {
        id: "inventory_detecion",
        name: "Inventory Deduction"
    },
    {
        id: "shopify_sells_report",
        name: "Shopify Sales Report"
    }
]