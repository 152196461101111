import React, { useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Card, CardBody, Col, Container, Input, Label, Row, Button, Table, Modal, ModalHeader, ModalBody,
    ModalFooter, Form, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { tabsOption } from "./helper";
import classnames from "classnames";
import { InvoiceEditTab, MsaReportTab,InventoryDetectionTab, ShopifySellsReportTab } from "./tabs";



function getActiveComponent(activeId) {
    switch (activeId) {
        case "invoice_edit_report":
            return InvoiceEditTab;
        case "msa_report":
            return MsaReportTab;
        case "inventory_detecion":
            return InventoryDetectionTab;
        case "shopify_sells_report":
            return ShopifySellsReportTab;
        default:
            return <></>;
    }
}


export default function AdminTools() {
    const [justifyPillsTab, setjustifyPillsTab] = useState("invoice_edit_report");
    const justifyPillsToggle = (tab) => {
        if (justifyPillsTab !== tab) {
            setjustifyPillsTab(tab);
        }
    };

    const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab]);

    return (
        <React.Fragment>
            <div className="sale-wh-wrapper">
                <Container fluid>
                    <Row>
                        <Col className="px-0" xs={12}>
                            <Nav pills className="nav mb-1 bg-light px-5">
                                {tabsOption.map(item => <NavItem key={item.id}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({ active: justifyPillsTab === item.id })}
                                        onClick={() => {
                                            justifyPillsToggle(item.id);
                                        }}
                                    >
                                        {item.name}
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                            <TabContent activeTab={justifyPillsTab} className="text-muted">
                                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                                    <TabComponent />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}