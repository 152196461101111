import React, {useEffect} from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "moment-timezone";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

//ContextProvider
import { PermissionsContextProvider } from "./context/PermissionsContext";
import { ToastContainer } from "react-toastify";


// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

function disableDevtool() {
  document.addEventListener("keydown", (e) => {
    // Disable F12
    if (
      e.key === "F12" 
    ) {
      e.preventDefault();
    }
  });
}
disableDevtool();
function App() {

  return (
    <React.Fragment>
      <ToastContainer />
      <PermissionsContextProvider>
        <BrowserRouter
          getUserConfirmation={(message, callback) => {
            // this is the default behavior
            const allowTransition = window.confirm(message);
            callback(allowTransition);
          }}
        >
          <Route />
        </BrowserRouter>
      </PermissionsContextProvider>
    </React.Fragment>
  );
}

export default App;

