import React, { useRef, useEffect, useState, useMemo, useCallback, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Input, Label, Row, Button, Table, Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import moment from "moment";
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";
import useScanDetection from "use-scan-detection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  actions,
  generateColumns,
  supplierColumnsList,
  supplierItemsColumnsList,
  generatePayload,
  mapPoDetails,
  paymentDetailsValidationSchema,
  focusSelectSupplierItemNumberInput,
  focusQuantityNumberInput,
  focusPriceNumberInput,
  focusLastSelectItemNumberInput,
  focusSelectItemNumberInput,
  focusManufacturingDateInput
} from "./helper";
import WhTableContainer from "../../Components/Common/WhTables";
import SelectItem from "../../Components/Common/SelectItem";
import PaymentDetails from "./PaymentDetails";
import DeleteModal from "../../Components/Common/DeleteModal";
import ConfirmationModal from "../../Components/Common/Confirmation";
import { Suppliers } from "./Suppliers";
import SmartSearch from "./SmartSearch";
import RouteChangeConfirmation from "../../Components/Common/RouteChangeConfirmation";
import ApiLoader from "../../Components/Common/ApiLoader";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import PriceChangeModal from "./PriceChangeModal"
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import {
  getSuppliersListAct,
  getItemsOfSupplierAct,
  getMasterDataForSupplierAct,
  getSupplierAct,
} from "../../store/supplier/supplieractions";
import { getInventoryListAct, activateInventoryItemAct } from "../../store/inventory/inventoryactions";
import {
  createPurchaseOrderAct,
  getPurchaseOrderDetailsAct,
  updatePurchaseOrderDetailsAct,
  receivePoAct,
  deletePoAct,
  getMaxPONoAct,
  openPoAct,
  downloadPoPdfAct,
} from "../../store/purchaseorder/purchaseorderactions";
import { logoutUser } from '../../store/actions';
//Utility
import { blockInvalidChar, formatAmount, formatAmountSymbol } from "../../utility/commonutility";
//Context
import { PermissionsContext } from "../../context/PermissionsContext"
//HOC
import withPermission from "../../hoc/withPermission"
import Draggable from "react-draggable";
import F12CloseModalListener from "../../Components/Common/F12close";
import PrintItem from "./PrintItem";
import { getInvPricingReportPrintAct } from "../../store/reports/reportsactions";
const boldText = {
  fontWeight : 'bold'
}

const tableStyles = {
  borderCollapse : 'separate',
  borderSpacing : '0px',
  border:'2px solid black'
}
 
const thStyle ={
  border:'5px solid black !important'
}

const initialTableRowValues = {
  // supplierItemNumber: "",
  stockItemNumber: "",
  description: "",
  // invoiceReceivedDate:moment().format("YYYY-MM-DD"),
  // manufacturingDate: moment().format("YYYY-MM-DD"),
  // expirationDate: moment().format("YYYY-MM-DD"),
  retail: "",
  cost: "",
  price: "",
  tax: "",
  quantity: "",
  caseQty: "",
  discount: "",
};
let modalsupplieropen;
let modalpricechangeopen;
const percentages = [3, 5, 7, 9, 12, 18];
function PurchaseOrder({ history }) {

  const [isBlocked, setIsBlocked] = useState(false);
  const [received, setReceived] = useState(false);
  const [viewItemsMode, setViewItemsMode] = useState("show_all_items");
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  const [tableData, setTableData] = useState([{ ...initialTableRowValues }]);
  const [invoicesList,setInvoicesList] = useState([])
  const [multipleInvoices,setMultipleInvoices] = useState([]);
  const [editedRowIndex, setEditedRowIndex] = useState(null);
  // const [supplierItemNumIndex,setSupplierItemNUmIndex] = useState(null);
  const isDownloadBtnClicked = useRef(false);

  const [supplierDetails, setSupplierDetails] = useState({
    supplierNumber: "",
    companyName: "",
    isOutOfState: false,
  });

  const invNumberRef = useRef('');
  const poDateRef = useRef('');
  const paymentTermRef = useRef('');
  const receivedDateRef = useRef('');
  const isNewInvoiceConfirmBtnClicked = useRef(false);

  const [search, setSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSmartSearchModal, setShowSmartSearchModal] = useState(false);
  const [showOpenPoConfirmModal, setShowOpenPoConfirmModal] = useState(false);
  const [showOpenSupplierItemConfirmModal, setShowOpenSupplierItemConfirmModal] = useState(false);
  const [showNewInvModel, setShowNewInvModel] = useState(false);
  const [suppliernumbercheck, setSupplierNumberCheck] = useState("")
  const [suppliersModalConfig, setSuppliersModalConfig] = useState({
    showModal: false,
    itemNumber: "",
    description: "",
  });
  const [activationModalConfig, setActivationModalConfig] = useState({
    show: false,
    itemNumber: "",
    description: "",
  });
  const [showPriceChangeModal, setShowPriceChangeModal] = useState(false);
  const { permissions, handleLogout } = useContext(PermissionsContext);

  const [selectedInv,setSelectedInv] = useState("");
  // const [isCreatingNewInvoice, setIsCreatingNewInvoice] = useState(false);
  const [tempInv,setTempInv] = useState("");
  const [invoiceReceivedDate,setInvoiceReceivedDate] = useState(moment().format("YYYY-MM-DD"));
  const [receivedInvoices,setReceivedInvoices] = useState([]);
  const [isDisable,setIsDisable] = useState(false);

  const itemToBeFocussed = useRef("itemNumber");
  const indexOfLastItemAdded = useRef("");

  const isFocusLastItemNumberInput = useRef(false);
  const indexOfCurrentAddedItem = useRef("");

  const [updateCostRetail, setUpdateCostRetail] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);

  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }

  const toggleOpenPoConfirmModal = () => setShowOpenPoConfirmModal(!showOpenPoConfirmModal);
  const toggleSupplierItemConfirmModal = () => {
    setShowOpenSupplierItemConfirmModal(!showOpenSupplierItemConfirmModal);
    modalsupplieropen = showOpenSupplierItemConfirmModal;
  }
  const toggleSmartSearchModal = () => setShowSmartSearchModal(!showSmartSearchModal);
  const togglePriceChangeModal = () => setShowPriceChangeModal(prev => !prev);
  const closeItemActivationModal = () => {
    setActivationModalConfig({
      show: false,
      itemNumber: "",
      description: "",
    });
  };
  const closeSuppliersModal = () =>
    setSuppliersModalConfig({
      showModal: false,
      itemNumber: "",
      description: "",
    });
  const openSuppliersModal = (itemNumber, description) => {
    setSuppliersModalConfig({
      showModal: true,
      itemNumber,
      description,
    });
  };
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  
  const tableRef = useRef();

  const handleUpdateCostRetailChange = () => {
    setUpdateCostRetail(!updateCostRetail); 
  };
  
  
  useEffect(()=>{
    if(selectedInv && receivedInvoices.length > 0){
      setIsDisable(receivedInvoices.includes(selectedInv))
    }
  },[selectedInv]);

  useEffect(() => {
    if (!received && supplierDetails.supplierNumber) setIsBlocked(true);
    else setIsBlocked(false);
  }, [received, supplierDetails.supplierNumber]);

  const { id } = useParams();
  const {
    loginDetails,
    createPurchaseOrderResp,
    updatePurchaseOrderDetailsResp,
    receivePoResp,
    deletePoResp,
    getPurchaseOrderDetailsResp,
    getMaxPONoResp,
    getSuppliersListResp,
    getItemsOfSupplierResp,
    getMasterDataForSupplierResp,
    getSupplierResp,
    openPoResp,
    downloadPoPdfResp,
    activateInventoryItemResp,
    supplierErrorResp,
    getInventoryListResp,
    getInventoryListLoading,
    purchaseOrderErrorResp,
    poApiLoading,
    getInvPricingReportLoading
  } = useSelector(
    ({ Login, supplier, purchaseOrder,reports, inventory }) => ({
      loginDetails: Login.loginDetails,
      createPurchaseOrderResp: purchaseOrder.createPurchaseOrderResp,
      updatePurchaseOrderDetailsResp: purchaseOrder.updatePurchaseOrderDetailsResp,
      receivePoResp: purchaseOrder.receivePoResp,
      deletePoResp: purchaseOrder.deletePoResp,
      getPurchaseOrderDetailsResp: purchaseOrder.getPurchaseOrderDetailsResp,
      getMaxPONoResp: purchaseOrder.getMaxPONoResp,
      getSuppliersListResp: supplier.getSuppliersListResp,
      getItemsOfSupplierResp: supplier.getItemsOfSupplierResp,
      getMasterDataForSupplierResp: supplier.getMasterDataForSupplierResp,
      getSupplierResp: supplier.getSupplierResp,
      openPoResp: purchaseOrder.openPoResp,
      downloadPoPdfResp: purchaseOrder.downloadPoPdfResp,
      activateInventoryItemResp: inventory.activateInventoryItemResp,
      supplierErrorResp: supplier.supplierErrorResp,
      getInventoryListResp: inventory.getInventoryListResp,
      getInventoryListLoading: inventory.getInventoryListLoading,
      purchaseOrderErrorResp: purchaseOrder.purchaseOrderErrorResp,
      poApiLoading: purchaseOrder.poApiLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
    }),
    shallowEqual
  );

  const handleLogoutUser = () => {
    dispatch(logoutUser(generateApiUrl('logout_user')));
    handleLogout();
  }

  const isPoSavedAfterSessionExpired = useRef(false);

  useEffect(() => {
    if(loginDetails?.sessionExpired) {
      if(isBlocked) {
        handlePurchaseOrderReceive(false);
        isPoSavedAfterSessionExpired.current = true;
      }
      else {
        handleLogoutUser();
      }
    }
  }, [loginDetails, isBlocked]);

  const isPaymentDetailsFormSubmitted = useRef(id !== "new");
  const paymentDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amountPaid: "",
      paymentType: "",
      chequeNo: "",
      chequeDate: "",
      inTheNameOf: "",
      bankName: "",
      transactionId: "",
      transactionDate: "",
    },
    validationSchema: paymentDetailsValidationSchema,
    onSubmit: (values) => {
      isPaymentDetailsFormSubmitted.current = true;
      togglePaymentDetailsModal();
    },
  });
  function togglePaymentDetailsModal() {
    setShowPaymentDetailsModal(!showPaymentDetailsModal);
    if (Object.keys(paymentDetailsValidation.errors).length > 0) paymentDetailsValidation.setErrors({});
  }
  const poDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      invNumber: "",
      poDate: moment().format("YYYY-MM-DD"),
      paymentTerm: "",
      receivedDate: moment().format("YYYY-MM-DD"),
      tax: "",
      freight: "",
      sgst:"",
      cgst:"",
      igst: "",
      other: "",
      selectedInvNumber:"",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      isPaymentDetailsFormSubmitted.current = true;
      togglePaymentDetailsModal();
    },
  });
  const dispatch = useDispatch();

  const isBarcodeScanDetected = useRef(false);

  useScanDetection({
    onComplete: (num) => {
      if (!isNaN(Number(num))) {
        if(supplierDetails?.supplierNumber) {
        const { supplierNumber } = supplierDetails;
        dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), { upcCode: num, source: "PO", supplierNumber }));
        isBarcodeScanDetected.current = true;
        } else {
          toast.error("Please select supplier");
        }
      } 
    },
    minLength: 12,
  });

  const isRouteChangeApiCalled = useRef(false);
  const isCreatePOBtnClicked = useRef(false);

  const currentSupplierListPage = useRef(1);
  const dropdownBtnClicked = useRef(false);
  const callbackOnSupplierListApiResp = useRef(() => {});

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "F3") {
        event.preventDefault();
        if (supplierDetails.supplierNumber) toggleSmartSearchModal();
        else toast.error("Please select supplier first");
      }
    },
    [showSmartSearchModal, supplierDetails]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    let timeout;
    if (supplierDetails.supplierNumber) {
      timeout = setTimeout(() => invNumberRef.current.focus(), 400);
    }
    return () => clearTimeout(timeout);
  }, [supplierDetails.supplierNumber]);

  const apiResp = useRef({
    prevCreatePurchaseOrderResp: createPurchaseOrderResp,
    prevUpdatePurchaseOrderDetailsResp: updatePurchaseOrderDetailsResp,
    prevReceivePoResp: receivePoResp,
    prevDeletePoResp: deletePoResp,
    prevGetPurchaseOrderDetailsResp: getPurchaseOrderDetailsResp,
    prevGetSuppliersListResp: getSuppliersListResp,
    prevGetSupplierResp: getSupplierResp,
    prevOpenPoResp: openPoResp,
    prevDownloadPoPdfResp: downloadPoPdfResp,
    prevActivateInventoryItemResp: activateInventoryItemResp,
    prevGetInventoryListResp: getInventoryListResp,
    prevSupplierErrorResp: supplierErrorResp,
    prevPurchaseOrderErrorResp: purchaseOrderErrorResp,
    prevGetItemsOfSupplierResp: getItemsOfSupplierResp,
  });

  useEffect(() => {
    const {
      prevCreatePurchaseOrderResp,
      prevUpdatePurchaseOrderDetailsResp,
      prevReceivePoResp,
      prevDeletePoResp,
      prevGetPurchaseOrderDetailsResp,
      prevGetSuppliersListResp,
      prevGetSupplierResp,
      prevOpenPoResp,
      prevDownloadPoPdfResp,
      prevActivateInventoryItemResp,
      prevGetInventoryListResp,
      prevSupplierErrorResp,
      prevPurchaseOrderErrorResp,
    } = apiResp.current || {};
    if (createPurchaseOrderResp && prevCreatePurchaseOrderResp !== createPurchaseOrderResp) {
      if(isPoSavedAfterSessionExpired.current) {
        handleLogoutUser();
        isPoSavedAfterSessionExpired.current = false;
      }
      else {
        if (!isRouteChangeApiCalled.current) {
          isCreatePOBtnClicked.current = true;
          setIsBlocked(false);
        } else isRouteChangeApiCalled.current = false;
      }
    }
    if (updatePurchaseOrderDetailsResp && prevUpdatePurchaseOrderDetailsResp !== updatePurchaseOrderDetailsResp) {
      if(isPoSavedAfterSessionExpired.current) {
        handleLogoutUser();
        isPoSavedAfterSessionExpired.current = false;
      }
      else {
        if (!isRouteChangeApiCalled.current) {
          toast.success("Successfully updated PO Details");
          getPurchaseOrderDetailsApiCall();
        } else isRouteChangeApiCalled.current = false;
      }
    }
    if (receivePoResp && prevReceivePoResp !== receivePoResp) {
      toast.success("Successfully received PO");
      getPurchaseOrderDetailsApiCall();
    }
    if (deletePoResp && prevDeletePoResp !== deletePoResp) {
      history.push("/warehousepurchaseorders", { showDeletePurchaseOrderSuccess: true });
    }
    if (getPurchaseOrderDetailsResp && prevGetPurchaseOrderDetailsResp !== getPurchaseOrderDetailsResp) {
      mapPoDetails(
        // setTableData,
        poDetailsValidation.setValues,
        paymentDetailsValidation.setValues,
        setSupplierDetails,
        setSearch,
        setReceived,
        getPurchaseOrderDetailsResp,
        setMultipleInvoices,
        setInvoicesList,
        setSelectedInv,
        setTempInv,
        setInvoiceReceivedDate,
        setReceivedInvoices,
        setIsDisable
      );
    }
    if (getSuppliersListResp && prevGetSuppliersListResp !== getSuppliersListResp) {
      const list = [];
      (getSuppliersListResp?.data?.suppliers || []).forEach(({ supplierNumber, firstName, lastName, supplierName }) => {
        list.push({
          supplierNumber,
          supplierName: `${firstName || ""} ${lastName || ""}`,
          companyName: supplierName,
        });
      });
      if (!dropdownBtnClicked.current) setSearchListOptions(list);
      else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getSuppliersListResp?.data?.suppliers?.length > 0) callbackOnSupplierListApiResp.current();
        currentSupplierListPage.current = Number(getSuppliersListResp?.data?.page) + 1;
      }
    }
    if (getSupplierResp && prevGetSupplierResp !== getSupplierResp) {
      const {
        data: {
          supplier: { firstName, lastName, supplierName, isOutOfState },
        },
      } = getSupplierResp;
      setSupplierDetails((prev) => ({ ...prev, isOutOfState }));
      // setSearch(`${firstName || ""} ${lastName || ""}${firstName || lastName ? "-" : ""}${supplierName || ""}`);
      setSearch(`${supplierName || ""}`);
    }
    if (openPoResp && prevOpenPoResp !== openPoResp) {
      toast.success("Successfully opened PO");
      getPurchaseOrderDetailsApiCall();
      toggleOpenPoConfirmModal();
    }
    if (downloadPoPdfResp && prevDownloadPoPdfResp !== downloadPoPdfResp) {
      if (isDownloadBtnClicked.current) {
      const file = new File([downloadPoPdfResp], "invoice.pdf", {
        type: "application/pdf",
      });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "PO.pdf");
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
    if (activateInventoryItemResp && prevActivateInventoryItemResp !== activateInventoryItemResp) {
      toast.success("Successfully activated inventory item");
      closeItemActivationModal();
    }
    if(getInventoryListResp && prevGetInventoryListResp !== getInventoryListResp) {
      if (isBarcodeScanDetected.current && Array.isArray(getInventoryListResp?.data?.inventories)) {
        isBarcodeScanDetected.current = false;
        if (getInventoryListResp.data.inventories.length === 0) toast.error("No product found for scanned barcode");
        else if (getInventoryListResp.data.inventories.length > 1)
          toast.error("More than one products found for scanned barcode");
        else {
          const {
            cost,
            description,
            category,
            itemNumber,
            retail,
            manufacturingDate,
            expirationDate,
            inventorySupplier,
            inventoryTax,
            active,
            units,
            caseQuantity,
            uCount
          } = getInventoryListResp?.data?.inventories[0] || {};
          const itemDetails = {
            cost: `${formatAmountSymbol()}${cost || 0}`,
            description,
            manufacturingDate:manufacturingDate || moment().format("YYYY-MM-DD"),
            expirationDate:expirationDate || moment().format("YYYY-MM-DD"),
            category,
            itemNumber,
            retail,
            supplierItemNumber: inventorySupplier?.supplierItemNumber || "",
            taxAmount: inventoryTax?.taxAmount,
            taxPercentage: inventoryTax?.taxPercentage,
            active,
            units,
            caseQuantity,
            uCount
          };
          selectedItemHandler(itemDetails);
        }
      }
    }
    if (supplierErrorResp && prevSupplierErrorResp !== supplierErrorResp) {
      const { errors, error, message } = supplierErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (purchaseOrderErrorResp && prevPurchaseOrderErrorResp !== purchaseOrderErrorResp) {
      const { errors, error, message } = purchaseOrderErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    apiResp.current.prevCreatePurchaseOrderResp = createPurchaseOrderResp;
    apiResp.current.prevUpdatePurchaseOrderDetailsResp = updatePurchaseOrderDetailsResp;
    apiResp.current.prevReceivePoResp = receivePoResp;
    apiResp.current.prevDeletePoResp = deletePoResp;
    apiResp.current.prevGetPurchaseOrderDetailsResp = getPurchaseOrderDetailsResp;
    apiResp.current.prevGetSuppliersListResp = getSuppliersListResp;
    apiResp.current.prevGetSupplierResp = getSupplierResp;
    apiResp.current.prevOpenPoResp = openPoResp;
    apiResp.current.prevDownloadPoPdfResp = downloadPoPdfResp;
    apiResp.current.prevActivateInventoryItemResp = activateInventoryItemResp;
    apiResp.current.prevGetInventoryListResp = getInventoryListResp;
    apiResp.current.prevSupplierErrorResp = supplierErrorResp;
    apiResp.current.prevPurchaseOrderErrorResp = purchaseOrderErrorResp;
  }, [
    createPurchaseOrderResp,
    updatePurchaseOrderDetailsResp,
    receivePoResp,
    deletePoResp,
    getPurchaseOrderDetailsResp,
    getSuppliersListResp,
    getSupplierResp,
    openPoResp,
    downloadPoPdfResp,
    activateInventoryItemResp,
    getInventoryListResp,
    supplierErrorResp,
    purchaseOrderErrorResp,
  ]);

  useEffect(() => {
    if (!isBlocked && isCreatePOBtnClicked.current) {
      history.push("/warehousepurchaseorders", { showCreatePurchaseOrderSuccess: true });
      isCreatePOBtnClicked.current = false;
    }
  }, [isBlocked]);

  useEffect(() => {
    if (search === "") setSearchListOptions([]);
  }, [search]);
  const getPurchaseOrderDetailsApiCall = () =>
    dispatch(getPurchaseOrderDetailsAct(generateApiUrl(`get_purchase_order_details`, { poNumber: id })));
  useEffect(() => {
    if (id && id !== "new") getPurchaseOrderDetailsApiCall();
    else initializePO();
  }, [id]);
  useEffect(() => {
    if (id && id === "new") {
      dispatch(getMaxPONoAct(generateApiUrl(`get_max_po_no`), { receivedDate: moment().format("YYYY-MM-DD") }));
    }
  }, [id, poDetailsValidation.values.receivedDate]);
  useEffect(() => {
    dispatch(getMasterDataForSupplierAct(generateApiUrl(`get_master_data_for_supplier`), { category: "paymentTerms" }));
  }, []);
  const getSuppliersListApiCall = useCallback(
    (showAllCustomers, page, download, downloadType="pdf",invoiceNumber="") => {
      let params = {};
      if (showAllCustomers) {
        let params = {
          page: page || 1,
          pageSize: 50,
          orderBy: "supplierName",
          sort: "asc",
          active:true,
        };
        dispatch(getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params));
        dropdownBtnClicked.current = true;
      }
      if (download && downloadType === "html") {
        params.download = true;
        params.downloadType = downloadType;
        params.invoiceNumber = invoiceNumber;
        console.log(selectedInv);
        dispatch(getInvPricingReportPrintAct(generateApiUrl("download_po_pdf", { poNumber: id }), params));
      } else if (download) {
        params.download = true;
        params.invoiceNumber = invoiceNumber;
        isDownloadBtnClicked.current = true;
        dispatch(downloadPoPdfAct(generateApiUrl("download_po_pdf", { poNumber: id }), params, { responseType: "blob" }));
      }
      else if (search) {
        let params = {
          page: 1,
          pageSize: 50,
          orderBy: "supplierName",
          sort: "asc",
          active:true,
        };
        if (search && isNaN(Number(search))) params.supplierName = search;
        else params.supplierNumber = search; 
        dispatch(getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params));
        dropdownBtnClicked.current = false;
      }
    },
    [search]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersListApiCall]);

  function selectItemCallbck(itemDetails) {
    const { supplierNumber, companyName } = itemDetails;
    setSupplierDetails((prev) => ({
      ...prev,
      supplierNumber,
      companyName,
    }));
  }
  useEffect(() => {
    if (supplierDetails.supplierNumber)
      dispatch(getSupplierAct(generateApiUrl("get_supplier", { supplierNumber: supplierDetails.supplierNumber })));
  }, [supplierDetails.supplierNumber]);

   const getItemsOfSupplierApiCall = useCallback(
      (props) => {
        
        const { description, itemNumber, supplierItemNumber, page, pageSize } = props || {};
        const { supplierNumber } = supplierDetails;
        if (supplierNumber !== "") {
          let params = {
            page: page || 1,
            pageSize: pageSize || 50,
          };
          if (description !== "") params.description = description;
  
          if (itemNumber !== "") params.itemNumber = itemNumber;
          if (supplierItemNumber !== "") params.supplierItemNumber = supplierItemNumber;
          setSupplierNumberCheck(supplierItemNumber)
          dispatch(getItemsOfSupplierAct(generateApiUrl(`get_items_of_supplier`, { supplierNumber }), params))
        }
      },
      [supplierDetails]
    );
  const callbackAfterGetItemsOfSupplierApiCall = useCallback((apiResp, setOptions, pageSize) => {
    const list = [];
    (apiResp?.data?.itemList || [])
      .slice(0, pageSize || 50)
      .forEach(
        ({
          cost,
          description,
          category,
          itemNumber,
          retail,
          supplierItemNumber,
          inventoryTax,
          active,
          units,
          caseQuantity,
          uCount
        }) => {
          list.push({
            cost: `${formatAmountSymbol()}${cost || 0}`,
            description,
            category,
            itemNumber,
            retail,
            supplierItemNumber,
            taxAmount: inventoryTax?.taxAmount,
            taxPercentage: inventoryTax?.taxPercentage,
            active,
            units,
            caseQuantity,
            uCount
          });
        }
      );
    setOptions(list);
  }, []);
  const getInventoryListApiCall = useCallback(
    (props) => {
      const { description, itemNumber, supplierItemNumber, page, pageSize,active } = props || {};
      const { supplierNumber } = supplierDetails;
      let params = {
        page: page || 1,
        pageSize: pageSize || 50,
        source: "PO",
        active: active !== undefined ? active : true, 
      };
      if (description) params.description = description;
      if (itemNumber) params.itemNumber = itemNumber;
      if (supplierNumber) params.supplierNumber = supplierNumber;
      if (supplierItemNumber) params.supplierItemNumber = supplierItemNumber;
      if (description !== "" || itemNumber !== "");
      dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
      if (activationModalConfig.itemNumber) {
        dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
      }
    },
    [supplierDetails.supplierNumber, activationModalConfig.itemNumber]
  );
    const callbackAfterGetInventoryListApiCall = useCallback((apiResp, setOptions, pageSize) => {
      const list = [];
      (apiResp?.data?.inventories || [])
        .slice(0, pageSize || 50)
        .forEach(
          ({
            cost,
            description,
            category,
            itemNumber,
            retail,
            inventorySupplier,
            inventoryTax,
            active,
            units,
            caseQuantity,
            uCount
          }) => {
            list.push({
              cost: `${formatAmountSymbol()}${cost || 0}`,
              description,
              category,
              itemNumber,
              retail:`${retail || 0}`,
              supplierItemNumber: inventorySupplier?.supplierItemNumber || "",
              taxAmount: inventoryTax?.taxAmount,
              taxPercentage: inventoryTax?.taxPercentage,
              active,
              units,
              caseQuantity,
              uCount
            });
          }
        );
      setOptions(list);
    }, []);
  useEffect(() => {
    getItemsOfSupplierApiCall();
  }, []);

  useEffect(() => {
    const { prevGetItemsOfSupplierResp } = apiResp.current || {};    
    if (getItemsOfSupplierResp && prevGetItemsOfSupplierResp !== getItemsOfSupplierResp) {
      const itemList = getItemsOfSupplierResp?.data?.itemList;
     if (suppliernumbercheck !== "" ) {
        if ((itemList.length === 0 || itemList.some(item => item.supplierItemNumber !== suppliernumbercheck)) && tableData.some(item => item.supplierItemNumber !== "")
        ) {
          toggleSupplierItemConfirmModal();
          setSupplierNumberCheck(""); 
        }}
    }
    apiResp.current.prevGetItemsOfSupplierResp = getItemsOfSupplierResp;
  }, [getItemsOfSupplierResp]);
  
  
  const addNewItem = () => {
    setTableData((prev) => {
      const updatedTableData = [...prev, { ...initialTableRowValues }];
      return updatedTableData;
    });
  };
  const removeItem = (index) => {
    setTableData((prev) => {
      const updatedTableData = [...prev];
      updatedTableData.splice(index, 1);
      itemToBeFocussed.current= "itemNumber";
      return updatedTableData;
    });
  };

  const incrementQuantity = (index,itemDetailsQuantity) => {
    setTableData(prev => {
      const updatedTableData = [...prev];
      updatedTableData.splice(index, 1, {
        ...updatedTableData[index],
        quantity:Number(updatedTableData[index].quantity) + ((Number(itemDetailsQuantity)) || 1)
      });
      return updatedTableData;
    })
  }
  const selectedItemHandler = (itemDetails, index) => {
    const alreadyAddedItem = tableData.slice(tableData.length - 2).find((item) => item.stockItemNumber === itemDetails.itemNumber);
    if ((index === undefined || index === tableData.length - 1) && alreadyAddedItem) {
      const alreadyAddetItemIndex = tableData.length - 2;
      indexOfLastItemAdded.current = alreadyAddetItemIndex;
      incrementQuantity(alreadyAddetItemIndex,itemDetails.quantity);
      itemToBeFocussed.current= "quantity";
    }
    else {
      setTableData((prev) => {
        const updatedTableData = [...prev],
          rowData = { ...updatedTableData[(index || index == 0) ? index : prev.length - 1] },
          { stockItemNumber: prevItemNumber } = rowData,
          {
            itemNumber,
            description,
            category,
            cost,
            retail,
            supplierItemNumber,
            manufacturingDate,
            expirationDate,
            taxAmount,
            taxPercentage,
            active,
            quantity,
            units,
            caseQuantity,
            uCount
          } = itemDetails;
        let price = Number(cost.slice(1)),
          tax = 0,
          profit = ((retail - cost.slice(1)) / retail) * 100;
        if (supplierDetails.isOutOfState) {
          if (taxAmount) {
            price = cost.slice(1) - taxAmount;
            tax = taxAmount;
          } else if (taxPercentage) {
            price = (100 * cost.slice(1)) / (100 + taxPercentage);
            tax = (cost.slice(1) * taxPercentage) / (100 + taxPercentage);
          }
        }
        updatedTableData.splice(((index || index == 0) ?  index : prev.length - 1), 1, {
          ...rowData,
          description,
          category,
          supplierItemNumber,
          manufacturingDate:manufacturingDate || moment().format("YYYY-MM-DD"),
          expirationDate:expirationDate || moment().format("YYYY-MM-DD"),
          retail: retail || "",
          stockItemNumber: itemNumber || "",
          cost: cost.slice(1),
          price: price.toFixed(2),
          tax: tax.toFixed(2),
          quantity: quantity || 1,
          caseQty: 1,
          profit,
          taxAmount,
          taxPercentage,
          units,
          caseQuantity,
          uCount
        });
        if (!prevItemNumber) {
          updatedTableData.push(initialTableRowValues);
        }
        if (!active) {
          setActivationModalConfig({
            show: true,
            itemNumber,
            description,
          });
          itemToBeFocussed.current = "none";
          return prev
        } else {
          itemToBeFocussed.current = "manufacturingDate";
        }
        indexOfLastItemAdded.current = index;
        return updatedTableData;
      });
    }
  };
  const selectedItemHandlerForSupplierItem = (itemDetails, index, value) => {
    if (itemDetails?.supplierItemNumber && itemDetails.supplierItemNumber == value) {
      const alreadyAddedItem = tableData.slice(tableData.length - 2).find((item) => item.stockItemNumber === itemDetails.itemNumber);
      if ((index === undefined || index === tableData.length - 1) && alreadyAddedItem) {
        const alreadyAddetItemIndex = tableData.length - 2;
        indexOfLastItemAdded.current = alreadyAddetItemIndex;
        incrementQuantity(alreadyAddetItemIndex);
        itemToBeFocussed.current= "quantity";
      }
      else {
        setTableData((prev) => {
          const updatedTableData = [...prev],
            rowData = { ...updatedTableData[index] },
            { stockItemNumber: prevItemNumber } = rowData,
            {
              itemNumber,
              description,
              category,
              cost,
              retail,
              supplierItemNumber,
              manufacturingDate,
              expirationDate,
              inventoryTax,
              active,
              units,
              caseQuantity,
              uCount
            } = itemDetails,
            { taxAmount, taxPercentage } = inventoryTax || {};
          let price = cost,
            tax = 0,
            profit = ((retail - cost) / retail) * 100;
          if (supplierDetails.isOutOfState) {
            if (taxAmount) {
              price = cost - taxAmount;
              tax = taxAmount;
            } else if (taxPercentage) {
              price = (100 * cost) / (100 + taxPercentage);
              tax = (cost * taxPercentage) / (100 + taxPercentage);
            }
          }
          updatedTableData.splice(index, 1, {
            ...rowData,
            description,
            category,
            supplierItemNumber: supplierItemNumber,
            manufacturingDate:manufacturingDate || moment().format("YYYY-MM-DD"),
            expirationDate:expirationDate || moment().format("YYYY-MM-DD"),
            retail: retail || "",
            stockItemNumber: itemNumber || "",
            cost: cost || "",
            price: price.toFixed(2) || "",
            tax: tax.toFixed(2),
            quantity: 1,
            caseQty: 1,
            profit,
            taxAmount,
            taxPercentage,
            units,
            caseQuantity,
            uCount
          });
          if (!prevItemNumber) {
            updatedTableData.push(initialTableRowValues);
          }
          if (!active) {
            setActivationModalConfig({
              show: true,
              itemNumber,
              description,
            });
            itemToBeFocussed.current = "none";
          } else {
            itemToBeFocussed.current = "quantity";
          }
          indexOfLastItemAdded.current = index;
          return updatedTableData;
        });
      }
    }
  };
  const fieldChangeHndlr = (key, value, index) => {
    setTableData((prev) => {
      const updatedTableData = [...prev],
        rowData = { ...updatedTableData[index] };
      let tax, retail, updatedRowData;
      if (key === "price") {
        const { profit, taxPercentage, taxAmount, tax: prevTax, retail: retailPrice } = prev[index];
        if (editedRowIndex === null) {
          setEditedRowIndex(index);
        }
        if (supplierDetails.isOutOfState && taxPercentage) tax = (value * taxPercentage) / 100;
        else if (supplierDetails.isOutOfState && taxAmount) tax = prevTax;
        else tax = 0;
        retail = (100 * (Number(value) + tax)) / (100 - profit);
        updatedRowData = { ...rowData, [key]: Number(value).toFixed(2), tax };
        if(Number(Number(value).toFixed(2)) > Number(retailPrice)) togglePriceChangeModal();
      } else if(key === "supplierItemNumber"){
        updatedRowData = { ...rowData, [key]: value };
          // setSupplierItemNUmIndex(index); 
      } else {
        updatedRowData = { ...rowData, [key]: value };
      }
      // itemToBeFocussed.current = "itemNumber";
      updatedTableData.splice(index, 1, updatedRowData);
      return updatedTableData;
    });
  };
  const handlePurchaseOrderReceive = (received) => {
    if (id === "new") {
      handlePurchaseOrderCreation(received);
    }
    else handlePurchaseOrderUpdation(received);
  };

  const createPoApiCall = (received) => {
    const payload = generatePayload(
      paymentDetailsValidation.values,
      poDetailsValidation.values,
      // tableData,
      supplierDetails.supplierNumber,
      totalAmount,
      poTotal,
      received,
      updateCostRetail,
      multipleInvoices,
    )
    
    dispatch(
      createPurchaseOrderAct(
        generateApiUrl("create_purchase_order"),
        payload
      )
    );
  };
  const handlePurchaseOrderCreation = (received) => {
    if (!isRouteChangeApiCalled.current) {
      if (!supplierDetails.supplierNumber) toast.error(`Please first select the supplier`);
      // else if (!poDetailsValidation.values.invNumber) toast.error(`Please first provide the invoice no`);
      else if (!poDetailsValidation.values.receivedDate) {
        toast.error("Please provide the received date");
      }
      else {
        const index = tableData.findIndex((item) => parseFloat(item.retail) < parseFloat(item.price));
        if (index !== -1) {
          toast.error(`Please set retail price greater than cost price for item at index ${index + 1}`);
        } 
        // else if (tableData.length === 1 && !tableData[0].stockItemNumber) {
        //   toast.error("Please add line items");
        // } 
        else {
          createPoApiCall(received);
        }
      }
    } else {
      createPoApiCall(received);
    }
  };

  const updatePoApiCall = (received) => {
  
    dispatch(
      updatePurchaseOrderDetailsAct(
        generateApiUrl("update_purchase_order_details", { poNumber: id }),
        generatePayload(
          paymentDetailsValidation.values,
          poDetailsValidation.values,
          // tableData,
          supplierDetails.supplierNumber,
          totalAmount,
          poTotal,
          received,
          updateCostRetail,
          multipleInvoices
        )
      )
    );
  };
  const handlePurchaseOrderUpdation = (received) => {
    if (!isRouteChangeApiCalled.current) {
      if (!supplierDetails.supplierNumber) toast.error("Please first select the supplier");
      else if (!selectedInv) toast.error("Please first provide the invoice no");
      else if (!poDetailsValidation.values.receivedDate) toast.error("Please provide the received date");
      // else if (tableData.length === 1 && !tableData[0].stockItemNumber) toast.error("Please add line items");
      else updatePoApiCall(received);
    } else updatePoApiCall(received);
  };
  const handlePurchaseOrderOpen = () => {
    if (id === "new") {
      setReceived(false);
      poDetailsValidation.setValues((prev) => ({ ...prev, receivedDate: "" }));
    } else dispatch(openPoAct(generateApiUrl("open_po", { poNumber: id }), { poDate: moment() }));
  };
const handlePriceChange = () => {
  setTableData((prev) => {
      if (editedRowIndex !== null) {
          const updatedTableData = [...prev];
          const itemNumberToFind = updatedTableData[editedRowIndex].stockItemNumber;
          const itemList = getInventoryListResp?.data?.inventories;
          if (itemList) {
              const matchingItem = itemList.find(item => item.itemNumber === itemNumberToFind);

              if (matchingItem) {
                  const costWithTax = Number(matchingItem.cost);
                  
                  const updatedRowData = {
                    ...updatedTableData[editedRowIndex],
                    price: Number(matchingItem.cost).toFixed(2),
                    cost: costWithTax.toFixed(2),
                  };
                  updatedTableData.splice(editedRowIndex, 1, updatedRowData);
              }
          }
          return updatedTableData;
      }
      return prev;
  });
  setEditedRowIndex(null);
    togglePriceChangeModal();
};
  
  const handlePaymentDetailsOpenModal = () => {
    if (!supplierDetails.supplierNumber) toast.error("Please first select the supplier");
    else if (!selectedInv) toast.error("Please first provide the invoice no");
    else togglePaymentDetailsModal();
  };
  const handleDeletePo = () => {
    if (id) {
      dispatch(deletePoAct(generateApiUrl("delete_po"), { ids: [id] }));
    }
  };

  useEffect(() => {
    if (!showSmartSearchModal  ) {
      if (isFocusLastItemNumberInput.current) {
        focusLastSelectItemNumberInput();
        // isFocusLastItemNumberInput.current = false;
      } else {
        focusQuantityNumberInput(indexOfCurrentAddedItem.current);
      }
    }
    else {
     const elements = document.getElementsByClassName("invoice-table")
      elements[0].scrollTop=elements[0].scrollHeight;
    }
  }, [showSmartSearchModal, tableData]);

  useEffect(() => {
    if (!showPriceChangeModal ) {
      focusLastSelectItemNumberInput();
      } 
      modalpricechangeopen = showPriceChangeModal;
  }, [showPriceChangeModal]);

  // useEffect(() => {
  //   if (!showOpenSupplierItemConfirmModal) {
  //     // focusLastSelectItemNumberInput();
  //   }
  //   modalsupplieropen = showOpenSupplierItemConfirmModal;
  // }, [showOpenSupplierItemConfirmModal]);

  const handleItemActivation = () => {
    dispatch(
      activateInventoryItemAct(
        generateApiUrl("activate_inventory_item", { itemNumber: activationModalConfig.itemNumber }),
        { status: "1" }
      )
    );
  };
  const viewItemsModeChngHndlr = (e) => {
    const { name } = e.target;
    if (viewItemsMode !== name) setViewItemsMode(name);
  };

  const initializePO = () => {
    setTableData([{ ...initialTableRowValues }]);
    poDetailsValidation.resetForm();
    paymentDetailsValidation.resetForm();
    setSupplierDetails({ supplierNumber: "", companyName: "", isOutOfState: false });
    setViewItemsMode("show_all_items");
    setSearch("");
    setReceived(false);
    setMultipleInvoices([]);
    setSelectedInv("");
    setTempInv("");
    setInvoicesList([]);
    setInvoiceReceivedDate(moment().format("YYYY-MM-DD"));
    setReceivedInvoices([]);
    setIsDisable(false);
  };
  const downloadPoHandler = () => {
    dispatch(downloadPoPdfAct(generateApiUrl("download_po_pdf", { poNumber: id }), {}, { responseType: "blob" }));
  };
  const {
    isReturnInvoice,
    totalItems,
    invSubTotal
  } = useMemo(() => {
    let returnInvoice = false;
    let totalInvItems = 0;
    let invSubTotal = 0;
    tableData.forEach((item) => {
      const { stockItemNumber, price, discount, quantity } = item;
      let priceVal = price || 0;
      let disPerVal = discount || 0;
      let total = priceVal * Number(quantity);
      let disVal = (total * disPerVal) / 100;
      invSubTotal += stockItemNumber ? total - disVal : 0;
      if (quantity < 0) returnInvoice = true;
      totalInvItems += Number(quantity)
    });
    return {
      isReturnInvoice: returnInvoice,
      totalItems: totalInvItems,
      invSubTotal: invSubTotal
    };
  }, [tableData]);
  const columns = useMemo(
    () =>
      generateColumns(
        supplierItemsColumnsList,
        getItemsOfSupplierApiCall,
        getInventoryListApiCall,
        callbackAfterGetItemsOfSupplierApiCall,
        callbackAfterGetInventoryListApiCall,
        removeItem,
        selectedItemHandler,
        selectedItemHandlerForSupplierItem,
        fieldChangeHndlr,
        viewItemsMode,
        openSuppliersModal,
        isReturnInvoice,
        received,
        tableData.length,
        showSmartSearchModal,
        poDetailsValidation,
        isDisable
      ),
    [ getInventoryListApiCall, viewItemsMode, isReturnInvoice, received, showSmartSearchModal, tableData.length]
  );
  // const totalItems = useMemo(() => {
  //   let total = 0;
  //   tableData.forEach((item) => (total += Number(item.quantity) || 0));
  //   return total;
  // }, [tableData]);
  const totalAmount = useMemo(() => {
    let totalAmt = 0;
    multipleInvoices.forEach(invoice => {
      const {subTotal} = invoice;
      totalAmt += Number(subTotal)
    });
    return totalAmt ? totalAmt : 0;
  }, [multipleInvoices]);
 
  // useEffect(() => { 
  //   let total = 0;
  //   tableData.forEach((item) => {
  //     total += Number(item.tax) * item.quantity || 0;
  //   });
  //   poDetailsValidation.setValues((prev) => ({ ...prev, tax: total }));
  // }, [tableData]);
  const poTotal = useMemo(() => {
    const { freight, other,tax, sgst, cgst, igst } = poDetailsValidation.values;
    
    // Calculate total without GST
    let totalWithoutGST = Number(totalAmount) + Number(freight) + Number(tax) + Number(other);
    
    // Initialize totalWithGST to totalWithoutGST
    let totalWithGST = totalWithoutGST;

    // If SGST and CGST are defined, add their percentages to totalWithGST
    if (sgst !== undefined && cgst !== undefined && igst !== undefined) {
        // Calculate SGST and CGST amounts based on totalAmount
        const sgstAmount = (Number(totalAmount) * Number(sgst)) / 100;
        const cgstAmount = (Number(totalAmount) * Number(cgst)) / 100;
        const igstAmount = (Number(totalAmount) * Number(igst)) / 100;
        // Add SGST and CGST amounts to totalWithGST
        totalWithGST += sgstAmount + cgstAmount + igstAmount;
    }
    
    return totalWithGST || 0;
}, [totalAmount, poDetailsValidation.values]);

  // const categoryWiseTotals = useMemo(() => {
  //   let totalTobacco = 0;
  //   let totalCigarette = 0;
  //   let totalOthers = 0;
  //   let cigaretteSticks = 0;
  //   tableData.forEach((item) => {
  //     if (item.stockItemNumber) {
  //       const { price, quantity, discount, category, uCount } = item;
  //       let priceVal = price || 0;
  //       let disPerVal = discount || 0;
  //       let itemTotal = priceVal * Number(quantity);
  //       let disVal = (itemTotal * disPerVal) / 100;
  //       if (category === "TOBACCO") {
  //         totalTobacco += itemTotal - disVal;
  //       } else if (category === "CIGARETTES") {
  //         totalCigarette += itemTotal - disVal;
  //         cigaretteSticks += quantity * (uCount || 0);
  //       } else {
  //         totalOthers += itemTotal - disVal;
  //       }
  //     }
  //   });
  //   return {
  //     totalTobacco,
  //     totalCigarette,
  //     totalOthers,
  //     cigaretteSticks,
  //   };
  // }, [tableData]);

  useEffect(() => {
    if(!showSmartSearchModal){
      const timeoutId = setTimeout(() => {
        focusLastSelectItemNumberInput();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [showSmartSearchModal]);
  useEffect(()=> {
    if(supplierDetails.isOutOfState && !received){
      poDetailsValidation.setValues((prev) => ({
        ...prev,
        tax:"0",
        igst: "18",
      }))

    }
},[supplierDetails.isOutOfState])
  const handleCreatingNewInvoice = (newInvoice) => {
    setShowNewInvModel(newInvoice);
  }
 const onConfirmClickCreateNewInvoiceHndlr = () => {
  setShowNewInvModel(false);
  poDetailsValidation.setValues(prev => ({
    ...prev,
    invNumber:""
  }));
  setTableData([{ ...initialTableRowValues }]);
  setTimeout(() => {
    if(!isNewInvoiceConfirmBtnClicked.current){
      isNewInvoiceConfirmBtnClicked.current = true
    }
  },100)
  setSelectedInv('');
 }
 const onCloseClickCreateNewInvoiceHndlr = () => {
  setShowNewInvModel(false);
  setTimeout(() => {
    if(isNewInvoiceConfirmBtnClicked.current){
      isNewInvoiceConfirmBtnClicked.current = false
    }
  },400)
 }
 useEffect(()=> {
  // if(selectedInv){
  //   poDetailsValidation.setValues((prev) => ({
  //         ...prev,
  //         invNumber: poDetailsValidation.values.selectedInvNumber
  //   }))
  // }
  const invoice = multipleInvoices.find(invoice => invoice.invoiceNumber === selectedInv);
  const tableData = invoice?.items || []

  if(!received && !isDisable){
    setTableData([...tableData,{...initialTableRowValues}]);
  }else{
    setTableData([...tableData]);
  }
 },[selectedInv,received])



 useEffect(()=>{
  if(selectedInv){
    setMultipleInvoices((prevInvoices) => 
      prevInvoices.map((invoice) => 
        invoice.invoiceNumber === selectedInv 
          ? { 
            ...invoice,
            invoiceReceivedDate: invoiceReceivedDate,
            subTotal: invSubTotal,
            items: tableData.filter(item => item.stockItemNumber !== "")} 
          : invoice
      )
    );
  }
 },[tableData,invoiceReceivedDate])
 
 const handleInputInvChange = (e) => {
  const inputValue = e.target.value
    setSelectedInv(inputValue);
 }

 const handleInputBlur = () => {
  setInvoicesList((prevList) => {
    // If creating a new invoice and not empty, add it to the list
    if (selectedInv !== '' && !invoicesList.includes(selectedInv)) {
      if(isNewInvoiceConfirmBtnClicked.current || invoicesList.length === 0){
        setTempInv(selectedInv);
        setMultipleInvoices([...multipleInvoices, { invoiceNumber: selectedInv,subTotal:'',invoiceReceivedDate:invoiceReceivedDate, items: [] }]);
        return [...prevList, selectedInv];
      }else{
        const prevSelectedInv = tempInv;
        setTempInv(selectedInv);
        setMultipleInvoices(multipleInvoices.map((item) => {
          if (item.invoiceNumber === prevSelectedInv) { // prevSelectedInvoice stores the invoice number before the edit
            setTableData([...item.items,{...initialTableRowValues}]);
            return { ...item, invoiceNumber: selectedInv };
          }
          return item;
        }));
        return prevList.map((invoice) =>
          invoice === prevSelectedInv ? selectedInv : invoice
        );
      }
    
    } else if (!isNewInvoiceConfirmBtnClicked.current && selectedInv === '') {
      // If editing an existing invoice, update it
      setInvoiceReceivedDate(moment().format("YYYY-MM-DD"))
      return prevList.filter((invoice) =>
        invoice !== tempInv
      );
    } else if(invoicesList.includes(selectedInv) && selectedInv !==tempInv){
      toast.error(`This invoice number is already exists ${selectedInv}`) 
    }
    return prevList;
  });
  setTimeout(() => {
    if(isNewInvoiceConfirmBtnClicked.current){
      isNewInvoiceConfirmBtnClicked.current = false
    }
  },400)
  // setIsCreatingNewInvoice(false); // Reset to editing mode after saving
};

const handleAllInvDropdownChange = (e) => {
  const selectedValue = e.target.value;
  setSelectedInv(selectedValue);
  setTempInv(selectedValue);
  const invoice = multipleInvoices.find(inv => inv.invoiceNumber === selectedValue);
  setInvoiceReceivedDate((invoice.invoiceReceivedDate).slice(0,10));
  // setIsCreatingNewInvoice(false); // Set to false since we're editing an existing invoice
  // const invoiceData = multipleInvoices.find(item => item.invoiceNumber === selectedValue);
  // setTableData(invoiceData.items)
  
}

const handleInputDateChange = (e) => {
  setInvoiceReceivedDate(e.target.value)
}

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-bg">
        <Container fluid  style={{fontWeight:'bold',}} >
          <Row className="px-4 wh-middle whbrand-bg whbrand-text-color">
            <Col xs={7}>
              <Row>
                <Col xs={6} className="border-section px-3 py-1">
                  <div className="py-0" >
                    <Label className="form-label mb-0" style={{fontWeight:'bold'}}>Search Supplier</Label>
                    <SelectItem
                      // onRef={supplierRef}
                      autoFocus = {id === 'new' ? true : false}
                      searchValue={search}
                      setSearchValue={setSearch}
                      listOptions={searchListOptions}
                      setSearchListOptions={setSearchListOptions}
                      columnsList={supplierColumnsList}
                      placeholder="Select Supplier"
                      selectedItemHandler={selectItemCallbck}
                      fieldKey="supplierName"
                      dataIdKey="supplierNumber"
                      showDropdownBtn={true}
                      addF3Shortcut={false}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        getSuppliersListApiCall(true);
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        getSuppliersListApiCall(true, currentSupplierListPage.current);
                        callbackOnSupplierListApiResp.current = fn;
                      }}
                      onDoubleClick={supplierDetails.supplierNumber ? () => window.open(`/warehousevendors/${supplierDetails.supplierNumber}`) : () => {}}
                      // onKeyDown = {(e) => handleKeyDown(e,invNumberRef)}
                      inputClassname="py-0"
                      btnClassName="py-0"
                      boldText={boldText}
                    />
                    
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-center border border-section pb-0 pt-1 px-1 h-100">
                    {actions(
                      history,
                      initializePO,
                      id,
                      getPurchaseOrderDetailsApiCall,
                      toggleDeleteModal,
                      downloadPoHandler,
                      togglePrintModal,
                      handlePaymentDetailsOpenModal,
                      received
                    ).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container ${
                           ((item.id === "delete" && received)||( id === "new" && ((item.id === "delete") || item.id === "print")) || (item.id === "delete" && !permissions["DELETE_OPEN_PO"]))
                              ? "opacity-50 show-cursor-not-allowed"
                              : "show-cursor-pointer"
                          }`}
                          onClick={
                            ((item.id === "delete" && received) || ( id === "new" && ((item.id === "delete") || item.id === "print")) || (item.id === "delete" && !permissions["DELETE_OPEN_PO"])) ? () => {} : item.clickHandler ? item.clickHandler : () => handlePaymentDetailsOpenModal
                          }
                        >
                          <img className="action-icon" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <div className="d-flex align-items-center mt-2">
                <div className="d-flex me-5">
                  <div className="form-check form-radio-outline form-radio-primary me-2" >
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="show_all_items"
                      id="show_all_items"
                      checked={viewItemsMode === "show_all_items"}
                      onChange={viewItemsModeChngHndlr}
                      disabled={received}
                    />
                    <Label className="form-check-show_all_items" for="flexRadioDefault1" style={{fontWeight:'bold'}}>
                      Show All Items
                    </Label>
                  </div>
                  <div className="form-check form-radio-outline form-radio-primary">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="supplier_items_only"
                      id="supplier_items_only"
                      checked={viewItemsMode === "supplier_items_only"}
                      onChange={viewItemsModeChngHndlr}
                      disabled={received}
                    />
                    <Label className="form-check-label" for="supplier_items_only" style={{fontWeight:'bold'}}>
                      Supplier Items Only
                    </Label>
                  </div>
                </div>
                <div className="px-3 py-1 me-5" style={{ backgroundColor:"palegreen" ,fontWeight:'bold' }}>
                  Total Items : {totalItems}
                </div>
                </div>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">Go Back</div>
                  </div>
                </div>
              )}
              <Col  className="px-2 ps-1 pb-0 mb-0 whbrand-bg" xs={12} >
              
              <div className="table-responsive mt-1 whbrand-table">
                <Table className="table-bordered align-middle table-nowrap mb-0 whbrand-text-color">
                  <thead className="wh-table-head">
                    <tr>
                  
                      <th scope="col" style={{ width: "12.5%",padding:'0.2rem 0.5rem', }}>
                        Invoice No
                      </th>
                  
                    {
                      invoicesList.length > 0 && (
                        <th scope="col" style={{ width: "12.5%",padding:'0.2rem 0.5rem', }}>
                          All Invoices
                        </th>
                      )
                    }
                      <th scope="col" style={{ width: "12.5%",padding:'0.2rem 0.5rem' }}>
                        Inv Received Date
                      </th>
                      <th scope="col" style={{ width: "12.5%",padding:'0.2rem 0.5rem' }}>
                        PO Date
                      </th>
                      <th scope="col" style={{ width: "12.5%",padding:'0.2rem 0.5rem' }}>
                        Payment Term
                      </th>
                      <th scope="col" style={{ width: "12.5%",padding:'0.2rem 0.5rem' }}>
                        Received Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    {  
                      <td  scope='col' style={{padding:'0rem 0rem',}}>
                  
                          
                            <Input
                          innerRef={invNumberRef}
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          className="border-0 "
                          id="invNumber"
                          name="invNumber"
                          value={selectedInv}
                          onChange={handleInputInvChange}
                          readOnly={received}
                          onKeyUp={e => {
                            if(e.key === "Enter") {
                              poDateRef.current.focus();
                            }
                          }}
                          onBlur={handleInputBlur}
                          style={{fontWeight:600}}
                          // onKeyDown={(e) => handleKeyDown(e,poDateRef)}
                        />
              
                        
                      </td>
                      }
                      {invoicesList.length > 0 && (
                        <td scope='col' style={{padding:'0rem 0rem',}}>
                        <Input
                          
                          type="select"
                          className="border-0 "
                          id="selectedInvNumber"
                          name="selectedInvNumber"
                          value={selectedInv}
                          onChange={handleAllInvDropdownChange}
                          
                          // disabled={received}
                          // readOnly={!received}
                          style={{fontWeight:600}}
                        >
                          {invoicesList.map((invoice,index) => <option key={`${invoice}`} value={`${invoice}`}>{invoice}</option>) }
                          
                          {/* {(getMasterDataForSupplierResp?.data?.paymentTerms || []).map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))} */}
                        </Input>  
                          
                      </td>
                      )}
                      <td  scope='col' style={{padding:'0rem' }}>
                        <Input
                          // innerRef={poDateRef}
                          type="date"
                          className="border-0 "
                          id="poDate"
                          name="poDate"
                          value={invoiceReceivedDate}
                          onChange={handleInputDateChange}
                          readOnly={received}
                          // onKeyUp={e => {
                          //   if(e.key === "Enter") {
                          //     receivedDateRef.current.focus();
                          //   }
                          // }}
                          style={{fontWeight:600}}
                          // onKeyDown={(e) => handleKeyDown(e,receivedDateRef)}
                        />
                      </td>
                      <td  scope='col' style={{padding:'0rem' }}>
                        <Input
                          innerRef={poDateRef}
                          type="date"
                          className="border-0 "
                          id="poDate"
                          name="poDate"
                          value={poDetailsValidation.values.poDate}
                          onChange={poDetailsValidation.handleChange}
                          readOnly={received}
                          onKeyUp={e => {
                            if(e.key === "Enter") {
                              receivedDateRef.current.focus();
                            }
                          }}
                          style={{fontWeight:600}}
                          // onKeyDown={(e) => handleKeyDown(e,receivedDateRef)}
                        />
                      </td>
                      <td  scope='col' style={{padding:'0rem 0rem' }}>
                        <Input
                          innerRef={paymentTermRef}
                          type="select"
                          className="border-0 "
                          id="paymentTerm"
                          name="paymentTerm"
                          value={poDetailsValidation.values.paymentTerm}
                          onChange={poDetailsValidation.handleChange}
                          disabled={received}
                          style={{fontWeight:600}}
                        >
                          <option value="">Select</option>
                          {(getMasterDataForSupplierResp?.data?.paymentTerms || []).map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Input>
                      </td>
                      {/* <td scope='col' style={{padding:'0rem 0.5rem',fontWeight:600 }}>${categoryWiseTotals.totalTobacco.toFixed(2)}</td>
                      <td scope='col' style={{padding:'0rem 0.5rem',fontWeight:600 }}>${categoryWiseTotals.totalCigarette.toFixed(2)}</td>
                      <td  scope='col' style={{padding:'0rem 0.5rem',fontWeight:600 }}>${categoryWiseTotals.totalOthers.toFixed(2)}</td>
                      <td   scope='col' style={{padding:'0rem 0.5rem',fontWeight:600 }}>{categoryWiseTotals.cigaretteSticks}</td> */}
                      <td  scope='col' style={{padding:'0rem 0rem',fontWeight:600 }}>
                        <Input
                          innerRef={receivedDateRef}
                          type="date"
                          className="border-0 "
                          id="receivedDate"
                          name="receivedDate"
                          value={poDetailsValidation.values.receivedDate}
                          onChange={poDetailsValidation.handleChange}
                          readOnly={received}
                          color="secondary"
                          onKeyUp={e => {
                            if(e.key === "Enter") {
                              focusSelectItemNumberInput(0);
                            }
                          }}
                          style={{fontWeight:600}}
                        />
                        {/* {poDetailsValidation.values.receivedDate
                          ? getMmddyyyyDateFormatted(poDetailsValidation.values.receivedDate)
                          : ""} */}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
             
          </Col>
              </Row>
             
            </Col>
            <Col xs={5}>
              <Row className="">
                <Col xs={6}>
                  <div className="d-flex flex-column align-items-center">
                    <div
                      className="fs-4 fw-bold d-flex align-items-center flex-column justify-content-center px-5 py-0 mb-2 wh-item-bg"
                      // style={{ background: "#FFC10754" }}
                    >
                        <span>PO #</span>
                        <span> {id !== "new" ? id : getMaxPONoResp?.data?.purchaseOrderNumber || ""}</span>
                    </div>
                    {received ? (
                      <>
                        <span className="badge badge-outline-success bg-light fs-3 mb-1">Received</span>
                        {/* <span className="text-muted">By Ali 05/01/2022 10 : 24 AM</span> */}
                        <div className={`px-5 mb-2 btn btn-outline-primary ${!permissions["OPEN_RECEIVED_PO"] ? "show-cursor-not-allowed" : "show-cursor-pointer"} wh-btn`}
                         onClick={permissions["OPEN_RECEIVED_PO"] ? toggleOpenPoConfirmModal : () => {}}
                         disabled={!permissions["OPEN_RECEIVED_PO"]}
                        >
                          Open
                        </div>
                      </>
                    ) : (
                      <div className="d-flex flex-column">
                        <Button
                          className="w-100 mb-2 wh-btn"
                          color="primary"
                          outline
                          onClick={() => handlePurchaseOrderReceive(true)}
                          style={{fontWeight:'bold'}}
                        >
                          Receive
                        </Button>
                        <div className="d-flex">
                            <Input
                              className="form-check-input me-2"
                              type="checkbox"
                              name="updateCostRetail"
                              id="updateCostRetail"
                              onClick={handleUpdateCostRetailChange}
                              checked={updateCostRetail}
                              // onChange={() => setUpdateCostRetail(!updateCostRetail)}
                              // disabled={received}
                            />
                            <Label className="form-check-update_inventory" for="updateCostRetail" style={{fontWeight:'bold'}}>
                              Update Cost
                            </Label>
                        </div>
                        {
                        !received && // !isNewInvoiceConfirmBtnClicked.current && 
                        (
                          <Button
                          className="w-100 mb-2 wh-btn-warning"
                          color="warning"
                          outline
                          onClick={() => handleCreatingNewInvoice(true)}
                          style={{fontWeight:'bold'}}
                        >
                          New Invoice
                        </Button>
                        )} 
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="h-100">
                    <div className="">
                      <div className="table-responsive whbrand-table">
                        <Table className="sales-table table-borderless mb-0 py-0 whbrand-text-color" style={{fontWeight:600}}>
                          <tbody>
                            <tr>
                              <td className="fw-medium" style={{padding:'0.2rem',fontWeight:600}}>Sub Total</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>{formatAmount(totalAmount.toFixed(2))}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium" style={{padding:'0.2rem',fontWeight:600}}>Freight</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                  <Input
                                    id="freight"
                                    name="freight"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="border-0 py-0"
                                    style={{ height: "20px",fontWeight:600,paddingLeft:'2.3rem' }}
                                    placeholder="0.00"
                                    value={poDetailsValidation.values.freight}
                                    onChange={poDetailsValidation.handleChange}
                                    onKeyDown={blockInvalidChar}
                                    readOnly={received}
                                    onBlur={() =>
                                      poDetailsValidation.setValues((prev) => ({
                                        ...prev,
                                        freight: Number(prev.freight).toFixed(2),
                                      }))
                                    }
                                  />
                                  <i 
                                  style={{ fontStyle: "normal",left:'8px'  }}
                                  >{formatAmountSymbol()}</i>
                                </div>
                              </td>
                            </tr>
                            {/* {!supplierDetails.isOutOfState && (
                              <tr>
                              <td className="" style={{padding:'0.2rem'}}>Tax</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                
                                {formatAmount((poDetailsValidation.values.tax || 0).toFixed(2))}
                              </td>
                            </tr>
                            )} */}
                            {
                              supplierDetails.isOutOfState && (
                                <tr>
                              <td className="fw-medium" style={{padding:'0.2rem',fontWeight:600}}>IGST</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                  <Input
                                    id="igst"
                                    name="igst"
                                    type="select"
                                    onWheel={(e) => e.target.blur()}
                                    className="border-0 py-0"
                                    style={{ height: "20px",fontWeight:600}}
                                    placeholder="0.00"
                                    value={poDetailsValidation.values.igst}
                                    onChange={poDetailsValidation.handleChange}
                                    onKeyDown={blockInvalidChar}
                                    readOnly={received}
                                    disabled={received}
                                    onBlur={() =>
                                      poDetailsValidation.setValues((prev) => ({
                                        ...prev,
                                        igst: Number(prev.igst),
                                      }))
                                    }
                                  >
                                   <option className="text-align-left" value="0">0 %</option>
                                       {percentages.map((percentage, index) => (
                                         <option className="text-align-left" key={index} value={percentage}>{percentage}%</option>
                                       ))}
                                     </Input>
                                </div>
                              </td>
                            </tr>
                              )
                            }
                            <tr>
                              <td className="fw-medium" style={{padding:'0.2rem',fontWeight:600}}>SGST</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                  <Input
                                    id="sgst"
                                    name="sgst"
                                    type="select"
                                    onWheel={(e) => e.target.blur()}
                                    className="border-0 py-0"
                                    style={{ height: "20px",fontWeight:600}}
                                    placeholder="0.00"
                                    value={poDetailsValidation.values.sgst}
                                    onChange={poDetailsValidation.handleChange}
                                    onKeyDown={blockInvalidChar}
                                    readOnly={received}
                                    disabled={received}
                                    onBlur={() =>
                                      poDetailsValidation.setValues((prev) => ({
                                        ...prev,
                                        sgst: Number(prev.sgst),
                                      }))
                                    }
                                  >
                                   <option value="">0 %</option>
                                       {percentages.map((percentage, index) => (
                                         <option key={index} value={percentage}>{percentage}%</option>
                                       ))}
                                     </Input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-medium" style={{padding:'0.2rem',fontWeight:600}}>CGST</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                <Input
                                       id="cgst"
                                       name="cgst"
                                       type="select"
                                       onWheel={(e) => e.target.blur()}
                                       className="border-0 py-0"
                                       style={{ height: "20px", fontWeight: 600}}
                                       placeholder="0.00"
                                       value={poDetailsValidation.values.cgst}
                                       onChange={poDetailsValidation.handleChange}
                                       onKeyDown={blockInvalidChar}
                                       readOnly={received}
                                       disabled={received}
                                       onBlur={() =>
                                         poDetailsValidation.setValues((prev) => ({
                                           ...prev,
                                           cgst: Number(prev.cgst),
                                         }))
                                       }
                                     >
                                       <option value="">0 %</option>
                                       {percentages.map((percentage, index) => (
                                         <option key={index} value={percentage}>{percentage}%</option>
                                       ))}
                                     </Input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="" style={{padding:'0.2rem'}}>Others</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                  <Input
                                    id="other"
                                    name="other"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="border-0 py-0"
                                    style={{ height: "20px",fontWeight:600,paddingLeft:'2.3rem' }}
                                    value={poDetailsValidation.values.other}
                                    onChange={poDetailsValidation.handleChange}
                                    onKeyDown={blockInvalidChar}
                                    placeholder="0.00"
                                    readOnly={received}
                                    disabled={received}
                                    onBlur={() =>
                                      poDetailsValidation.setValues((prev) => ({
                                        ...prev,
                                        other: Number(prev.other).toFixed(2),
                                      }))
                                    }
                                  />
                                  <i style={{ fontStyle: "normal", left: "8px", }}>{formatAmountSymbol()}</i>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-medium" style={{padding:'0.2rem',fontWeight:600}}>Inv SubTotal</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>{formatAmount(invSubTotal.toFixed(2))}</td>
                            </tr>
                            <tr>
                              <td className="" style={{padding:'0.2rem'}}>Total</td>
                              <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>{formatAmount(poTotal.toFixed(2))}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            
          
          </Row>
          <Row className="px-3 pe-0"  style={{ zIndex: "99",width:'100%',}}>
            <Col className="py-3 pb-0" xs={12} 
                        style={{zIndex: 1}}>
              <div ref={tableRef} style={{zIndex: 1}}>
                <WhTableContainer
                  columns={columns}
                  data={supplierDetails.supplierNumber ? tableData : tableData}
                  className="custom-header-css overflow-y-scroll"
                  divClass="table-responsive invoice-table mt-1 table-container-po-new"
                  thClass={"py-1 sticky-top px-2"}
                  tdClass="p-0 px-2"
                  tableClass="align-middle table-bordered "
                  tableHover={false}
                />  
              </div>           
              </Col>
            </Row>
            
              {supplierDetails.supplierNumber && !received && (
                   <Row>
                    <Col className="px-3" xs={12} style={{zIndex:1030,position:'fixed',bottom:3,padding:'0.2rem 0.9rem'}}>
                    <Button onClick={addNewItem} className="wh-btn" outline>
                      + Add Item
                    </Button>
                    </Col>
                  </Row>     
              )}
           
        </Container>
      </div>
      <Draggable cancel=".cancledrag">
      <Modal
        id="showPaymentDetailsModal"
        isOpen={showPaymentDetailsModal}
        toggle={togglePaymentDetailsModal}
        centered
        size="lg"
      >
        <F12CloseModalListener onClose={togglePaymentDetailsModal} />
        <ModalHeader className="py-2 px-3" toggle={togglePaymentDetailsModal}>
          Payment Details
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0 cancledrag">
            <PaymentDetails
              invNumber={poDetailsValidation.values.invNumber}
              validation={paymentDetailsValidation}
              supplierDetails={supplierDetails}
              totalAmount={totalAmount}
              received={received}
              togglePaymentDetailsModal={togglePaymentDetailsModal}
              poTotal={poTotal}
            />
          </ModalBody>
        </Form>
      </Modal>
      </Draggable>
      <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           invoiceNumber={selectedInv}
           getSuppliersListApiCall={getSuppliersListApiCall}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
      <Draggable  cancel=".cancledrag">
      <Modal
        id="suppliersModal"
        isOpen={suppliersModalConfig.showModal}
        toggle={closeSuppliersModal}
        centered
        size="xl"
      >
           <F12CloseModalListener onClose={closeSuppliersModal} />
        <ModalHeader className="py-2 px-3" toggle={closeSuppliersModal}>
          Suppliers - {suppliersModalConfig.description}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0 cancledrag">
            <Suppliers itemNumber={suppliersModalConfig.itemNumber} />
          </ModalBody>
        </Form>
      </Modal>
      </Draggable>
      <Draggable cancel=".cancledrag" centered>
      <Modal id="smartSearchModal" isOpen={showSmartSearchModal} toggle={toggleSmartSearchModal} centered size="xl">
      <F12CloseModalListener onClose={toggleSmartSearchModal} />
        <ModalHeader className="py-2 px-3 border-bottom mb-2" toggle={toggleSmartSearchModal}>
          Select Inventory Item
        </ModalHeader>
        <ModalBody className="px-0 pt-0 cancledrag">
          <SmartSearch
            getInventoryListApiCall={getInventoryListApiCall}
            callbackAfterGetInventoryListApiCall={callbackAfterGetInventoryListApiCall}
            getItemsOfSupplierApiCall={getItemsOfSupplierApiCall}
            callbackAfterGetItemsOfSupplierApiCall={callbackAfterGetItemsOfSupplierApiCall}
            selectedItemHandler={selectedItemHandler}
            toast={toast}
            loading={getInventoryListLoading}
          />
        </ModalBody>
      </Modal>
      </Draggable>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeletePo} />
      <ConfirmationModal
        show={activationModalConfig.show}
        onCloseClick={closeItemActivationModal}
        title={`${activationModalConfig.itemNumber}-${activationModalConfig.description}`}
        confirmationText={"The item is inactive, do you want to activate? "}
        onConfirmClick={handleItemActivation}
      />
      <ConfirmationModal
        show={showOpenPoConfirmModal}
        onCloseClick={toggleOpenPoConfirmModal}
        title={`Open PO`}
        confirmationText={"Do you want to open the Purchase Order?"}
        onConfirmClick={handlePurchaseOrderOpen}
      />
      {/* <ConfirmationModal
        show={showOpenSupplierItemConfirmModal}
        onCloseClick={handleSupplierItemInput}
        title={`Create New Item`}
        confirmationText={"This Supplier Number Is Not In The Inventory. Do you Want to Add This? "}
        onConfirmClick={ ()=>{

          toggleSupplierItemConfirmModal();
            focusLastSelectItemNumberInput();
        }
        }
      /> */}
      <RouteChangeConfirmation
        isBlocked={isBlocked}
        title="Leave Page?"
        content="You have unsaved changes. Are you sure you want to leave this page?"
        saveDetailsHndlr={() => {
          isRouteChangeApiCalled.current = true;
          handlePurchaseOrderReceive(false);
        }}
        routeChangeApiResp={id === "new" ? createPurchaseOrderResp : updatePurchaseOrderDetailsResp}
        routeChangeApiErrResp={purchaseOrderErrorResp}
        toast={toast}
      />
      <ConfirmationModal 
        show={showNewInvModel}
        onConfirmClick={onConfirmClickCreateNewInvoiceHndlr}
        onCloseClick={onCloseClickCreateNewInvoiceHndlr}
        title="Creating new invoice#"
        confirmationText="Do you want to create new invoice# ?"
      />
      <ApiLoader loading={poApiLoading} />
      <ApiLoader loading={getInvPricingReportLoading} />
      <PaymentsAnywhere />
      <PriceChangeModal isOpen={showPriceChangeModal} onCloseClick={handlePriceChange} toggle={togglePriceChangeModal}/>
    </React.Fragment>
  );
}

export const isModalOpenSelector = () => {
    return modalsupplieropen;
  };
export const isModalPriceSelector = () => {
    return modalpricechangeopen;
  };
  
export default withPermission(PurchaseOrder, "PO_MENU");

