import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { generateApiUrl } from "../../../../api/apihelper";
import downloadIcon from "../../../../assets/images/warehouse/salespage/download-icon.png";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import { getSHopifySellsReportAct } from "../../../../store/reports/reportsactions";
import {
  getEndDateFilterValue,
  getStartDateFilterValue,
} from "../../../../utility/dateutility";

export function ShopifySellsReportTab() {
  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [weekDate, setWeekDate] = useState(() => {
    const currentDay = moment();
    let currentWeekSaturday = currentDay.clone().startOf("isoWeek").add(5, "days");
    const previousSaturday = currentDay.clone().subtract(5, "day");

    if (currentDay.isBefore(currentWeekSaturday, "day")) {
      currentWeekSaturday = previousSaturday;
    }

    return currentWeekSaturday.format("YYYY-MM-DD");
  });

  useEffect(() => {
    setFilters({
      ...filters,
      startDate: moment(weekDate).subtract(7, "days").format("YYYY-MM-DD"),
      endDate: weekDate,
    });
    setWeekText(`Week of Sat ${moment(weekDate).format("MMM DD, YYYY")}`);
  }, []);

  const [weekText, setWeekText] = useState("Current Week");
  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const {
    getShopifySellsReportResp,
    getShopifySellsReportLoading,
    reportsErrorResp,
  } = useSelector(
    ({ reports }) => ({
      reportsErrorResp: reports.reportsErrorResp,
      getShopifySellsReportLoading: reports.getShopifySellsReportLoading,
      getShopifySellsReportResp: reports.getShopifySellsReportResp,
    }),
    shallowEqual
  );

  const customerResp = useRef({
    prevGetShopifySellsReportResp: getShopifySellsReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const { prevReportsErrorResp, prevGetShopifySellsReportResp } = customerResp.current || {};
    if (getShopifySellsReportResp && prevGetShopifySellsReportResp !== getShopifySellsReportResp) {
      if (isDownloadBtnClicked.current && getShopifySellsReportResp) {
        const url = window.URL.createObjectURL(new Blob([getShopifySellsReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SHOPIFY_SALES_REPORT.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    }
    if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetShopifySellsReportResp = getShopifySellsReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getShopifySellsReportResp, reportsErrorResp]);

  const getShopifySellsReportApiCall = useCallback(
    (type) => {
      const { startDate, endDate } = filters;
      let params = {};
      if (startDate) params.startDate = startDate; // Only the date in YYYY-MM-DD format
      if (endDate) params.endDate = endDate; // Only the date in YYYY-MM-DD format
      if (type === "DOWNLOAD") {
        params.download = "true";
        isDownloadBtnClicked.current = true;

        dispatch(
          getSHopifySellsReportAct(
            generateApiUrl(`get_shopify_sells_report`),
            params,
            {
              responseType: "blob",
              headers: {
                "Content-Disposition": `attachment; filename="SHOPIFY_SELLES_REPORT"`,
              },
            }
          )
        );
      }
    },
    [dispatch, filters]
  );

  const handleDownloadClick = () => {
    getShopifySellsReportApiCall("DOWNLOAD");
  };

  return (
    <Container fluid className="pt-4 pb-0">
      <Row>
        <Col xs="12" md="6" className="mb-3">
          <div className="border rounded shadow-sm p-4 position-relative bg-light">
            <Label className="text-dark fs-5 position-absolute" style={{ top: "-20px", left: "15px" }}>
              <span className="px-2 wh-item-bg py-1 rounded">Shopify Sales Details</span>
            </Label>
            <Row>
              <Col xs="12">
                <Row className="mb-4">
                  <Col xs="12" md="6" className="mb-3">
                    <Label htmlFor="startDate" className="form-label mb-1 text-muted">Date From:</Label>
                    <Input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={filters.startDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                  <Col xs="12" md="6" className="mb-3">
                    <Label htmlFor="endDate" className="form-label mb-1 text-muted">Date To:</Label>
                    <Input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={filters.endDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>
                <Row className="">
                  <Col xs="12" md="3">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn"
                      outline
                      onClick={handleDownloadClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img src={downloadIcon} alt="Download" style={{ height: "16px", width: "16px" }} />
                        <span className="ms-2 text-nowrap">Download</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ApiLoader loading={getShopifySellsReportLoading} />
    </Container>
  );
}
