import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  FormGroup,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select,{ components } from "react-select";
import DeleteModal from "../../../../Components/Common/DeleteModal"
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SpecialPricesIcon from "../../../../assets/images/warehouse/salespage/special-prices.png";
import AddBarcodes from "./AddBarcodes";
import SpecialPrices from "../Details/specialprices";
import { calculateUnitRetail, getDropdownOptions, conditionalValidationBasedOnCategory } from "./helper";
//Utility
import { blockInvalidChar, formatAmountSymbol, toTitleCase } from "../../../../utility/commonutility";
import { toast } from "react-toastify";
// import { deleteInventoryColumnValuesAct, getInventoryAllMasterDataAct } from "../../../../store/inventory/inventoryactions";
function getSelectValue(value) {
  if (value) return { value, label: value };
  else return { label: "Select...", value: "" };
}
function getSelectOptions(options) {
  return [{ label: "Select...", value: "" }, ...options];
}
const { Option } = components;

export const DetailsTab = ({
  dropdownState,
  setDropdownState,
  id,
  inventoryDetailsValidation: validation,
  // inventoryFinancialValidation: { setValues: setFinancial },
  dispatch,
  generateApiUrl,
  getSpecialPricesResp,
  getAllCustomersResp,
  createCustomerInventoryPriceResp,
  deleteSpecialPriceForCustomerResp,
  // deleteInventoryColumnValuesResp,
  permissions,

}) => {
  const [modalConfig, setModalConfig] = useState({
    showModal: false,
    modalTitle: "",
    id: "",
  });
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const toggleBarcodeModal = () => setShowBarcodeModal(!showBarcodeModal);
  const customField = useRef("");

  const [showSpecialPricesModal, setShowSpecialPricesModal] = useState(false);
  const prevCreateCustomerInventoryPriceResp = useRef(createCustomerInventoryPriceResp);
  // const prevDeleteInventoryColumnValuesResp = useRef(deleteInventoryColumnValuesResp);
  useEffect(() => {
    if (
      createCustomerInventoryPriceResp &&
      createCustomerInventoryPriceResp !== prevCreateCustomerInventoryPriceResp.current
    ) {
      toast.success("Successfully saved customer special prices");
      toggleSpecialPricesModal();
    }
    prevCreateCustomerInventoryPriceResp.current = createCustomerInventoryPriceResp;
  }, [createCustomerInventoryPriceResp]);
  function toggleSpecialPricesModal() {
    setShowSpecialPricesModal(!showSpecialPricesModal);
  }
  function toggleModal(modalTitle, field) {
    setModalConfig({
      showModal: !modalConfig.showModal,
      modalTitle: modalTitle || "",
    });
    if (field) {
      setDropdownState((prev) => ({ ...prev, modalCustomField: dropdownState[field] }));
      customField.current = field;
    } else {
      setDropdownState((prev) => ({ ...prev, modalCustomField: "" }));
    }
  }
  const handleSelectChange = (option, key) => {
    validation.setValues((prev) => ({ ...prev, [key]: option.value }));
  };
  const {
    category,
    manufacturer,
    customCategory,
    customManufacturer,
  } = dropdownState;
  
  const handleCostChange = (e) => {
    const { value } = e.target;
    const { retail } = validation.values;
    const profit = retail ? (((retail - value) / retail) * 100).toFixed(2) : "";
    validation.setValues((prev) => ({ ...prev, cost: value, profit }));
  };
  const handleRetailPriceChange = (e) => {
    const { value } = e.target;
    const { cost } = validation.values;
    const profit = value ? (((value - cost) / value) * 100).toFixed(2) : "";
    // setDetails((prev) => ({ ...prev, retail: Number(value).toFixed(2) }));
    validation.setValues((prev) => ({ ...prev, retail: value, profit }));
  };
  const handleProfitChange = (e) => {
    const { value } = e.target;
    const { cost } = validation.values;
    const retail = ((100 * cost) / (100 - value)).toFixed(2);
    validation.setValues((prev) => ({ ...prev, retailPrice: retail, profit: value }));
    // setDetails((prev) => ({ ...prev, retail }));
  };

  // const handleRemove = (value, type) => {
  //       const column = {
  //        type: type,
  //        value: value
  //         };
  //     dispatch(deleteInventoryColumnValuesAct(generateApiUrl("delete_inventory_column_values"), { columns: [column] }));
  // };
  
//   const CustomOption = (props) => {
//     const { type } = props;
//     const isFirstOption = props.data && props.data.value === "";
//   return (
//       <Option {...props}>
//       <div className="d-flex flex-row justify-content-between px-3">
//           <span className="">{props.label}</span>
//         {!isFirstOption && (
//           <span className="" style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }} onClick={(e) => {handleRemove(props.value,type); }}>&#x2715;</span>
//         )}
//       </div>
//     </Option>
//   );
// };
const CustomOption = (props) => {
  const { type, removeOption } = props; // Added removeOption prop
  const isFirstOption = props.data && props.data.value === "";

  const handleRemoveClick = (e) => {
    e.stopPropagation(); // Prevent unnecessary propagation
    removeOption(props.value, type); // Call removeOption function
  };
  // useEffect(() => {
  //   if (
  //     deleteInventoryColumnValuesResp &&
  //     deleteInventoryColumnValuesResp !== prevDeleteInventoryColumnValuesResp.current
  //   ) {
  //     toast.success("Deleted Successfully");
  //   }
  //   prevDeleteInventoryColumnValuesResp.current = deleteInventoryColumnValuesResp;
  // }, [deleteInventoryColumnValuesResp]);
  return (
    <Option {...props}>
      <div className="d-flex flex-row justify-content-between px-3">
        <span className="">{props.label}</span>
        {!isFirstOption && (
          <span
            className=""
            style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }}
            onClick={handleRemoveClick} // Changed to call handleRemoveClick
          >
            &#x2715;
          </span>
        )}
      </div>
    </Option>
  );
};
const handleRemove = (value, type) => {
  const column = {
    type: type,
    value: value
  };
  // dispatch(deleteInventoryColumnValuesAct(generateApiUrl("delete_inventory_column_values"), { columns: [column] }));
    if(value === validation.values[type]){
      setTimeout(()=> {
        validation.setValues((prev) => ({ ...prev, [type]: '' }));
      
      },200)
     }
   if(type === 'category' || type === 'manufacturer'){
    setTimeout(()=> {
     setDropdownState((prev) => {
      return {...prev,customCategory:"",customManufacturer:""}
     })
    },300)
   }
  

};
// useEffect(()=> {
//   setTimeout(() =>  dispatch(getInventoryAllMasterDataAct(generateApiUrl(`get_inventory_all_master_data`))),700);
// },[deleteInventoryColumnValuesResp])

  return (
    <>
      <Container fluid className="px-5 py-1 ">
        <Form>
          <Row>
            <Col xs="8">
              <Row>
                <Col xs="9">
                  <FormGroup>
                    <Label htmlFor="description" className="form-label mb-0 text-nowrap p-0 required">
                    Product Name
                    </Label>
                    <Input
                      className="form-control"
                      placeholder="Enter Product Name"
                      type="text"
                      name="description"
                      id="description"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      invalid={validation.touched.description && validation.errors.description ? true : false}
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                    <FormFeedback>
                      {validation.touched.description && validation.errors.description
                        ? validation.errors.description
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <FormGroup className="mb-0">
                    <Label htmlFor="salesLimit" className="form-label mb-0 text-nowrap p-0">
                      Sales Limit
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="salesLimit"
                      id="salesLimit"
                      value={validation.values.salesLimit}
                      onChange={validation.handleChange}
                      onKeyDown={blockInvalidChar}
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <FormGroup className="mb-0">
                    <Label htmlFor="category" className="form-label mb-0 text-nowrap p-0 required">
                      Category
                    </Label>
                    <div className="d-flex">
                      <div className="flex-grow-1 me-2 whbrand-custom-select">
                        <Select
                          id="category"
                          options={getSelectOptions(getDropdownOptions(category, customCategory))}
                          value={getSelectValue(validation.values.category)}
                          className={`${
                            validation.touched.category && validation.errors.category ? "custom-invalid-field" : ""
                          }`}
                          onChange={(option) => handleSelectChange(option, "category")}
                          isDisabled={!permissions["UPDATE_INV_PRICES"]}
                        />
                        {validation.touched.category && validation.errors.category && (
                          <div className="custom-invalid-feedback">{validation.errors.category}</div>
                        )}
                      </div>
                      <i
                        className={`ri-pencil-line fs-5 mt-1 wh-pencil show-cursor-${
                          permissions["UPDATE_INV_PRICES"] ? "pointer" : "not-allowed opacity-50"
                        }`}
                        onClick={
                          permissions["UPDATE_INV_PRICES"]
                            ? () => toggleModal("Add Category", "customCategory")
                            : () => {}
                        }
                      ></i>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup className="mb-0">
                    <Label htmlFor="manufacturer" className="form-label mb-0 text-nowrap p-0">
                      Manufacturer
                    </Label>
                    <div className="d-flex">
                      <div className="flex-grow-1 me-2">
                        <Select
                          id="manufacturer"
                          options={getSelectOptions(getDropdownOptions(manufacturer, customManufacturer))}
                          value={getSelectValue(validation.values.manufacturer)}
                          className={`whbrand-text-color ${
                            validation.touched.manufacturer && validation.errors.manufacturer
                              ? "custom-invalid-field"
                              : ""
                          }`}
                          onChange={(option) => handleSelectChange(option, "manufacturer")}
                          isDisabled={!permissions["UPDATE_INV_PRICES"]}
                        />
                        {validation.touched.manufacturer && validation.errors.manufacturer && (
                          <div className="custom-invalid-feedback">{validation.errors.manufacturer}</div>
                        )}
                      </div>
                      <i
                        className={`ri-pencil-line fs-5 mt-1 wh-pencil show-cursor-${
                          permissions["UPDATE_INV_PRICES"] ? "pointer" : "not-allowed opacity-50"
                        }`}
                        onClick={
                          permissions["UPDATE_INV_PRICES"]
                            ? () => toggleModal("Add Manufacturer", "customManufacturer")
                            : () => {}
                        }
                      ></i>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="cost" className="form-label mb-0 text-nowrap p-0 required">
                      Cost
                    </Label>
                    <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="cost"
                        id="cost"
                        value={validation.values.cost}
                        onChange={handleCostChange}
                        onBlur={() => validation.setValues((prev) => ({ ...prev, cost: Number(prev.cost).toFixed(2) }))}
                        invalid={validation.touched.cost && validation.errors.cost ? true : false}
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                    {validation.touched.cost && validation.errors.cost && (
                      <div className="custom-invalid-feedback">{validation.errors.cost}</div>
                    )}
                  </FormGroup>
                </Col>
                {/* <Col xs="4">
              <FormGroup>
                <Label htmlFor="subCategory" className="form-label mb-0 text-nowrap p-0">
                  Sub-Categories
                </Label>
                <div className="d-flex">
                  <div className="flex-grow-1 me-2 whbrand-custom-select">
                    <Select
                      id="subCategory"
                      options={getSelectOptions(getDropdownOptions(subCategory, customSubCategory))}
                      value={getSelectValue(validation.values.subCategory)}
                      onChange={(option) => handleSelectChange(option, "subCategory")}
                      isDisabled={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </div>
                  <i
                    className={`ri-pencil-line fs-5 mt-1 whbrand-text-color show-cursor-${
                      permissions["UPDATE_INV_PRICES"] ? "pointer" : "not-allowed opacity-50"
                    }`}
                    onClick={
                      permissions["UPDATE_INV_PRICES"]
                        ? () => toggleModal("Add Sub Category", "customSubCategory")
                        : () => {}
                    }
                  ></i>
                </div>
              </FormGroup>
            </Col> */}
              </Row>
            </Col>
            <Col xs="3" className="ps-5">
              <div className="d-flex align-items-center mb-1">
                <Label className="form-label mb-0 text-nowrap fs-5 fw-bold">Item Status:</Label>
                <div className="form-check form-switch form-switch-lg form-switch-success px-5 ms-2 d-flex align-items-center">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="active"
                    checked={validation.values.active}
                    onChange={validation.handleChange}
                    disabled={!permissions["UPDATE_INV_PRICES"]}
                  />
                  <span className={`fs-5 fw-bold px-2 py-1 rounded text-light ${validation.values.active ? "bg-success" : "bg-danger"}`}>
                        {validation.values.active ? "Active" : "Inactive"}
                  </span>
                </div>
              </div>
              {id === "new" ? (
                <Button color="primary" className="mb-2 w-100 p-0 wh-btn" outline onClick={validation.handleSubmit} disabled={!permissions["UPDATE_INV_PRICES"]}>
                  <span className="d-flex justify-content-center align-items-center">
                    <i className="ri-save-line" style={{ fontSize: "28px" }}></i>
                    <span className="ms-2">Save</span>
                  </span>
                </Button>
              ) : (
                <Button color="primary" className="mb-2 w-100 wh-btn" outline onClick={validation.handleSubmit} disabled={!permissions["UPDATE_INV_PRICES"]}>
                  <span className="d-flex justify-content-center align-items-center">
                    <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
                    <span className="ms-2">Update Info</span>
                  </span>
                </Button>
              )}

              <Button color="primary" className="mb-2 w-100 wh-btn" 
              onClick={toggleSpecialPricesModal} 
              outline disabled={!permissions["UPDATE_INV_PRICES"]}>
                <span className="d-flex justify-content-center align-items-center">
                  <img src={SpecialPricesIcon} style={{ height: "32px", width: "32px" }} />
                  <span className="ms-2">Special Customer Price</span>
                </span>
              </Button>
              
            </Col>
          </Row>
          <Row className="mb-1 whbrand-text-color">
            <Col xs="2">
              <FormGroup>
                <Label htmlFor="retail" className="form-label mb-0 text-nowrap p-0 required">
                  Retail Price
                </Label>
                <div className="form-icon">
                  <Input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control form-control-icon"
                    name="retail"
                    id="retail"
                    value={validation.values.retail}
                    onChange={handleRetailPriceChange}
                    placeholder="0.00"
                    onBlur={() => {
                      validation.setValues((prev) => ({ ...prev, retail: Number(prev.retail).toFixed(2) }));
                      // setFinancial((prev) => ({ ...prev, retailPrice: Number(prev.retailPrice).toFixed(2) }));
                    }}
                    invalid={validation.touched.retail && validation.errors.retail ? true : false}
                    readOnly={!permissions["UPDATE_INV_PRICES"]}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
                {validation.touched.retail && validation.errors.retail && (
                  <div className="custom-invalid-feedback">{validation.errors.retail}</div>
                )}
              </FormGroup>
            </Col>
                <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="units" className="form-label mb-0 text-nowrap p-0 required">
                      Units
                    </Label>
                    <div className="d-flex">
                      <div className="flex-grow-1 me-2">
                        <Input
                          name="casePack"
                          id="casePack"
                          className="form-control whbrand-text-color"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={validation.values.casePack}
                          onChange={validation.handleChange}
                          readOnly={!permissions["UPDATE_INV_PRICES"]}
                        />
                        {validation.touched.casePack && validation.errors.casePack && (
                          <div className="custom-invalid-feedback">{validation.errors.casePack}</div>
                        )}
                      </div>
                    </div>
                    
                  </FormGroup>
                </Col>
                <Col xs="2">
                   <FormGroup className="mb-0">
                     <Label htmlFor="companyName" className="form-label mb-0 text-nowrap p-0">
                         Barcode
                         </Label>
                         <div className="d-flex">
                          <div className="flex-grow-1 me-2">
                            <Input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="upcCode"
                            id="upcCode"
                            value={validation.values.upcCode}
                            onChange={validation.handleChange}
                            readOnly={!permissions["UPDATE_INV_PRICES"]}
                            />
                            </div>
                            <i
                            className={`ri-pencil-line fs-5 mt-1  show-cursor-${
                              permissions["UPDATE_INV_PRICES"] ? "pointer" : "not-allowed opacity-50"
                            }`}
                            onClick={permissions["UPDATE_INV_PRICES"] ? toggleBarcodeModal : () => {}}>
                            </i>
                            </div>
                            </FormGroup>
                </Col>
                  
                <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="unitRetail" className="form-label mb-0 text-nowrap p-0">
                      Unit Retail
                    </Label>

                    <div className="form-icon">
                      <Input
                        id="unitRetail"
                        className="form-control form-control-icon whbrand-text-color"
                        type="text"
                        readOnly
                        value={calculateUnitRetail(validation.values.retail, validation.values.casePack)}
                        placeholder="0.00"
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="profitPercent" className="form-label mb-0 text-nowrap p-0">
                      Profit %
                    </Label>
                    <Input
                      className="form-control"
                      placeholder="0.00"
                      type="text"
                      id="profitPercent"
                      value={validation.values.profit}
                      onChange={handleProfitChange}
                      onBlur={() =>
                        validation.setValues((prev) => ({ ...prev, profit: Number(prev.profit).toFixed(2) }))
                      }
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </FormGroup>
                </Col>
          </Row>

          <Row className="mb-1">
                <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="caseQuantity" className="form-label mb-0 text-nowrap p-0">
                      Master Case Qty
                    </Label>
                    <div className="d-flex">
                      <div className="flex-grow-1 me-2">
                    <Input
                      className="form-control"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="caseQuantity"
                      id="caseQuantity"
                      value={validation.values.caseQuantity}
                      onChange={validation.handleChange}
                      invalid={validation.touched.caseQuantity && validation.errors.caseQuantity ? true : false}
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                    {validation.touched.caseQuantity && validation.errors.caseQuantity && (
                          <div className="custom-invalid-feedback">{validation.errors.caseQuantity}</div>
                    )}
                    </div>
                    </div>
                    <FormFeedback>
                      {validation.touched.caseQuantity && validation.errors.caseQuantity
                        ? validation.errors.caseQuantity
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="weight" className="form-label mb-0 text-nowrap p-0">
                      Weight
                    </Label>
                    <Input
                      className="form-control"
                      id="weight"
                      type="number"
                      value={validation.values.weight}
                      onChange={validation.handleChange}
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </FormGroup>
            </Col>
            <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="weightUnit" className="form-label mb-0 text-nowrap p-0">
                      Unit
                    </Label>
                    <select
                      className="form-select mb-1"
                      id="weightUnit"
                      name="weightUnit"
                      value={validation.values.weightUnit}
                      onChange={validation.handleChange}
                      disabled={!permissions["UPDATE_INV_PRICES"]}
                    >
                      <option value="">Select Unit </option>
                      <option>g</option>
                      <option>lb</option>
                      <option>oz</option>
                    </select>
                  </FormGroup> 
            </Col>
            <Col xs="2">
                  <FormGroup>
                    <Label htmlFor="asin" className="form-label mb-0 text-nowrap p-0">
                      ASIN
                    </Label>
                    <Input
                      className="form-control"
                      id="asin"
                      type="text"
                      placeholder="Enter ASIN"
                      value={validation.values.asin}
                      onChange={validation.handleChange}
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </FormGroup>
            </Col>
            <Col xs="2">
              <div className="form-check mt-4">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="deductBags"
                  name="deductBags"
                  checked={validation.values.deductBags}
                  onChange={validation.handleChange}
                />
                <Label className="form-check-label text-nowrap" htmlFor="deductBags">
                  Deduct Bag On Purchase
                </Label>
              </div>
            </Col>
            
          </Row>

        </Form>
      </Container>
      <Modal isOpen={modalConfig.showModal} toggle={() => toggleModal()}>
        <ModalHeader className="p-3" toggle={() => toggleModal()}>
          <span className="modal-title" id="exampleModalLabel">
            {modalConfig.modalTitle}
          </span>
        </ModalHeader>
        <ModalBody>
          <Input
            // id={modalConfig.id}
            type="text"
            value={dropdownState.modalCustomField}
            onChange={(e) => setDropdownState((prev) => ({ ...prev, modalCustomField: e.target.value }))}
          />
        </ModalBody>
        <ModalFooter className="">
          <div className="hstack gap-2 justify-content-end">
            <Button
              color="primary"
              onClick={() => {
                setDropdownState((prev) => ({ ...prev, [customField.current]: dropdownState.modalCustomField }));
                setDropdownState((prev) => ({ ...prev, modalCustomField: "" }));
                toggleModal();
              }}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showBarcodeModal} toggle={() => toggleBarcodeModal()} centered size="lg">
        <ModalHeader className="p-3" toggle={() => toggleBarcodeModal()}>
          <span className="modal-title">Add Barcodes</span>
        </ModalHeader>
        <ModalBody className="p-0 pb-4">
          <AddBarcodes
            barcodes={validation.values.barcodes}
            setDetails={validation.setValues}
            toggleBarcodeModal={toggleBarcodeModal}
          />
        </ModalBody>
      </Modal>
      <Modal id="showSearchModal" isOpen={showSpecialPricesModal} toggle={toggleSpecialPricesModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSpecialPricesModal}>
          Customer Price
        </ModalHeader>
        <ModalBody className="px-0 pt-0">
          <SpecialPrices
            dispatch={dispatch}
            generateApiUrl={generateApiUrl}
            getSpecialPricesResp={getSpecialPricesResp}
            id={id}
            getAllCustomersResp={getAllCustomersResp}
            toggleSpecialPricesModal={toggleSpecialPricesModal}
            deleteSpecialPriceForCustomerResp={deleteSpecialPriceForCustomerResp}
            toast={toast}
          />
        </ModalBody>
      </Modal>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleRemove} />
    </>
  );
};
