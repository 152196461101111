import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import BarcodeIcon from "../../assets/images/warehouse/salespage/bar-code-icon.png";
import AttachmentIcon from "../../assets/images/warehouse/salespage/attachment.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import bundlesIcon from "../../assets/images/warehouse/salespage/bundles-icon.png";
import bundleIcon from "../../assets/images/warehouse/salespage/bundle.png";
import InventoryIcon from "../../assets/images/warehouse/header/inventory.png";
//Helper
import { calculateProfitPercent } from "./tabs/Financial/helper";
import { getYyyymmddDateFormatted, getMmddyyyyDateFormatted } from "../../utility/dateutility";

export const tabs = [
  {
    id: "details",
    name: "Details",
  },
  // {
  //   id: "financial",
  //   name: "Financial",
  // },
  {
    id: "suppliers",
    name: "Suppliers",
  },
  {
    id: "purchases",
    name: "Purchases",
  },
  {
    id: "sales",
    name: "Sales",
  },
  // {
  //   id: "features",
  //   name: "Features",
  // },
];

export const actions = (
  history,
  inventoryDetailsValidation,
  // inventoryFinancialValidation,
  justifyPillsToggle,
  setSearch,
  toggleDeleteModal,
  id,
  getBundleItemApiCall,
  getInventoryItemApiCall,
  setUpcSearch,
  path,
  handleBundleReset
) => [
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => {
      history.push("/warehouseinventory/new");
    },
  },
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: () => {
      if (id === "new") {
        inventoryDetailsValidation.resetForm();
        // inventoryFinancialValidation.resetForm();
        setSearch("");
        setUpcSearch("");
      } else if (path.startsWith(`/warehouseinventory/bundles-createbundle/${id}`)) {
        getInventoryItemApiCall();
      } else if (path.startsWith(`/warehouseinventory/bundles-createbundle`)) {
        handleBundleReset();
      }
      else{
        // getBundleItemApiCall();
        setUpcSearch("");
      }
      justifyPillsToggle("details");
    },
  },
  {
    id: "inventory",
    label: "Inventory",
    icon: InventoryIcon,
    clickHandler: () => history.push("/warehouseinventory"),
  },
  {
    id: `${ id === 'bundles'? 'create_bundle':'bundles'}`,
    label:`${ id === 'bundles'? 'Create Bundle':'Bundles'}`,
    icon: `${ id === 'bundles'? bundleIcon:bundlesIcon}`,
    clickHandler:() => history.push(`/warehouseinventory/${ id === 'bundles'? 'bundles-createbundle':'bundles'}`)

  },
  // {
  //   id: "barcode",
  //   label: "Barcode",
  //   icon: BarcodeIcon,
  // },
  // {
  //   id: "attachment",
  //   label: "Attachment",
  //   icon: AttachmentIcon,
  // },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler: toggleDeleteModal,
  },
];

export const mapInventoryDetails = (apiResp, setDetails, initialValidationValues) => {
  const {
    description,
    salesLimit,
    category,
    manufacturer,
    subCategory,
    family,
    cost,
    retail,
    casePack,
    unitCharge,
    stateReport,
    caseQuantity,
    palletQuantity,
    uCount,
    weight,
    weightUnit,
    location,
    location2,
    location3,
    location4,
    groupCode,
    orderQuantity,
    model,
    asin,
    iColor,
    upcCode,
    active,
    barcodes,
    deductBags
  } = apiResp.data.inventory;
  const details = {
    description: description || "",
    salesLimit: salesLimit || "",
    category: category || "",
    manufacturer: manufacturer || "",
    subCategory: subCategory || "",
    family: family || "",
    cost: cost?.toFixed(2) || "",
    retail: retail?.toFixed(2) || "",
    profit: cost && retail ? (((retail - cost) / retail) * 100).toFixed(2) : "",
    casePack: casePack || "",
    unitCharge: unitCharge || "",
    class: apiResp.data.inventory.class || "",
    stateReport: stateReport || "",
    caseQuantity: caseQuantity || "",
    palletQuantity: palletQuantity || "",
    uCount: uCount || "",
    model: model || "",
    weight: weight || "",
    asin: asin || "",
    weightUnit: weightUnit || "",
    location: location || "",
    location2: location2 || "",
    location3: location3 || "",
    location4: location4 || "",
    groupCode: groupCode || "",
    orderQuantity: orderQuantity || "",
    iColor: iColor || "#777777",
    upcCode: upcCode || "",
    active,
    barcodes: barcodes || "",
    deductBags: deductBags || false
  };
  setDetails(details);
  initialValidationValues.current = {
    details,
  };
};
function appendDataToPayload(values, obj) {
  const keys = Object.keys(values);
  keys
    .filter((key) => key !== "barcodes")
    .forEach(
      (key) =>
        (obj[key] =
          values[key] || typeof values[key] === "boolean" ? values[key] : values[key] === 0 ? values[key] : null)
    );
}
export const generatePayload = (detailsValues, financialValues) => {
  const payload = {};
  if (detailsValues) {
    appendDataToPayload(detailsValues, payload);
    const barcodes = [];
    (detailsValues?.barcodes || []).forEach((item) => {
      if (item.upcCode) {
        barcodes.push(item);
      }
    });
    payload.barcodes = barcodes;
  }
  if (financialValues) appendDataToPayload(financialValues, payload);

  return {
    ...payload,
    // casePack: 0,
    // uCount: 0,
    taxedWeightUnit: "-",
    profitPercent: calculateProfitPercent(financialValues.cost, financialValues.retailPrice),
  };
};

export const inventoryItemColumnsList = [
  {
    id: "itemNumber",
    name: "Item Num",
    cellClassName: "fw-medium",
  },
  {
    id: "itemName",
    name: "Item Name",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "category",
    name: "Category",
    cellClassName: "fw-medium",
  },
];

export function generateAdjustmentHistoryColumns(page) {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      Cell: ({ row }) => (page - 1) * 10 + (row.index + 1),
    },
    {
      Header: "Last Inventory Date",
      accessor: "dateStamp",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : "-"),
    },
    {
      Header: "Inv. Date Qty",
      accessor: "quantity",
    },
    {
      Header: "Adjustments",
      accessor: "quantityAdj",
    },
  ];
}
